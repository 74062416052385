const prequalAppIDKey = 'prequal_application_id'
const tokenKey = 'wisetack:ba:token'

export const savePrequalAppId = (id) => {
    try {
        localStorage.setItem(prequalAppIDKey, id);
    } catch {
        // ignore write errors
    }
}

export const loadPrequalAppId = () => {
    try {
        const id = localStorage.getItem(prequalAppIDKey);
        if (id === null) {
            return undefined;
        }
        return id;
    } catch (err) {
        return undefined;
    }
}

export const loadToken = () => {
    try {
        const token = sessionStorage.getItem(tokenKey);
        if (token === null) {
            return undefined;
        }
        return token;
    } catch (err) {
        return undefined;
    }
}