import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {getAuthAmount, getLoanStatus, getFMPConfig, sessionExpired} from "../../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {LoaderWithMessage} from "../../components/LoaderWithMessage";
import classNames from "classnames";
import styles from "./CardCollectionPage.module.scss";
import CardCollectionLearnMoreModal from "./CardCollectionLearnMoreModal";
import {nextPageForStatus} from "../ConsumerEntryPage";
import Ticker from "@wisetack/shared-ui/components/Ticker";
import CardCollectionForm from "./CardCollectionForm";
import uuid from "uuid";
import {
    ABSOLUTE_TIMEOUT,
    CARD_COLLECTED_MESSAGE,
    CARD_COLLECTION_PAGE_NAME,
    CLOSE_POPUPS_MESSAGE,
    PARENT_SESSION_DATA,
    POPUP_OPENED_MESSAGE,
    RE_ENABLE_ADD_DEBIT_CARD_BUTTON_MESSAGE
} from "./CardCollectionConstants";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";


const firstMonthPaymentBoxClasses = classNames({
    [styles.firstMonthPayment]: true,
    "row": true
});

const firstMonthPaymentTitleClasses = classNames({
    [styles.title]: true,
    "col-8": true
});

const firstMonthPaymentAmountClasses = classNames({
    [styles.title]: true,
    "col-4": true
});

const addDebitCardBtnClasses = classNames({
    btn: true,
    "btn-block": true,
    "btn-disabled": false,
    [styles.buttonDisabled]: false,
    [styles.buttonEnabled]: true
});


class CardCollectionPage extends React.Component {

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: CARD_COLLECTION_PAGE_NAME,
        };
        logAmplitudeEvent(CARD_COLLECTION_PAGE_NAME, this.logProps);

        if (!!this.props.submitCardDataId && this.props.status === "OFFER_AVAILABLE") {
            if (this.props.cardLastFourDigits) {
                this.props.history.push("/link_bank")
            }
            this.props.history.push("/choose_plan")
        }

        if (this.isInIframe()) {
            this.setUpWindowListener();
        }

        this.setState({addDebitCardBtnDisabled: false});
        this.props.getAuthAmount(this.props.loanAppId);
        this.props.getFMPConfig(this.props.loanAppId, uuid.v4());

    }

    componentDidUpdate = (prevProps) => {
        if (!this.props.firstMonthPrepayment) {
            this.handleIncorrectEntry();
        } else if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        } else if (this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
            this.props.history.push("/link_bank");
        } else if (this.props.cardLastFourDigits) {
            this.props.history.push("/link_bank");
        } else if (prevProps.status === "CONDITIONAL_APPROVAL" && this.props.status === "OFFER_AVAILABLE") {
            this.props.history.push("/link_bank")
        } else if (this.props.submitCardDataId !== prevProps.submitCardDataId && this.props.status === "OFFER_AVAILABLE") {
            this.props.history.push("/link_bank")
        } else if (this.props.status === "CONDITIONAL_APPROVAL" || this.props.status === "OFFER_AVAILABLE") {
            //stay here!
        } else {
            this.handleIncorrectEntry();
        }

    }

    handleIncorrectEntry = () => {
        let page = nextPageForStatus(this.props);
        if (page[0]) {
            this.props.history.push(page[0]);
        } else {
            this.props.history.push("/error");
        }
    }

    handleOnTicker = () => {
        const session = sessionStorage.getItem('wisetack:captureContextSession');
        const sessionData = session ? session.split(':') : null;
        if (sessionData && sessionData.length === 2) {
            if (this.props.loanAppId && sessionData[0] === this.props.loanAppId.substr(0, 8)) {
                const sessionExpiration = Number(sessionData[1]) + ABSOLUTE_TIMEOUT;
                if (sessionExpiration < Date.now()) {
                    this.handleOnAbsoluteExpiration();
                }
            }
        }
    }

    handleOnAbsoluteExpiration = () => {
        if (!this.props.location.pathname.match(/session_.*_expiration/)) {
            logAmplitudeEvent('Session Absolute Expiration', {loanAppId: this.props.loanAppId})
            this.props.sessionExpired();
            this.props.history.replace('/session_absolute_expiration');
        }
    }

    isInIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    setUpWindowListener = () => {
        window.addEventListener("message", (e) => {
            if (e.origin !== window.location.origin || !e.data.message) {
                return;
            }

            switch (e.data.message) {
                case POPUP_OPENED_MESSAGE:
                    const token = loadToken();
                    const sessionToken = sessionStorage.getItem("wisetack:ba:token");
                    const session = sessionStorage.getItem("wisetack:session");
                    e.source.postMessage(
                        { message: PARENT_SESSION_DATA,
                            token: token,
                            loanAppExpirationDate: this.props.loanAppExpirationDate,
                            sessionToken: sessionToken,
                            session: session });
                    break;
                case RE_ENABLE_ADD_DEBIT_CARD_BUTTON_MESSAGE:
                    this.setState({ addDebitCardBtnDisabled: false });
                    break;
                case CARD_COLLECTED_MESSAGE:
                    this.props.getLoanStatus(loadToken());
                    break;
                default:
                    break;
            }
        }, false)
    }

    handleOnAddDebitCardClick = () => {
        const popup = window.open('/#/card_collection_popup', '_blank', 'opener');
        this.setState({addDebitCardBtnDisabled: true});

        window.addEventListener("beforeunload", () => {
            popup.postMessage({ message: CLOSE_POPUPS_MESSAGE }, window.location.origin);
        });

    }

    isFmpAuthAndSave = () => {
        return !!this.props.fmpConfig
        && this.props.fmpConfig.strategy === "AUTH_AND_SAVE"
    }

    render = () => {

        const {
            status,
            authAmount,
            isLoading,
            expectedRunLength
        } = this.props;


        const showContent = status && authAmount && !isLoading;
        const isFmpAuthAndSave = this.isFmpAuthAndSave();

        return (
            <>
                <Ticker timeout={10000} callback={this.handleOnTicker} enabled={true}/>
                <Container>
                    <CardCollectionLearnMoreModal
                        loanExpirationDate={this.props.loanAppExpirationDate}
                        authAmount={this.props.authAmount}
                        isFmpAuthAndSave={isFmpAuthAndSave}
                    />
                    <PageHeader progress="40%">
                        {showContent &&
                            <Fragment>
                                <div>
                                    Add a debit card
                                </div>
                            </Fragment>
                        }
                        {showContent &&
                            <div className={styles.content} style={{textAlign: "center"}}>
                                <p>
                                    In order to process your application, we will
                                    <br/>need a debit card on file.
                                    <br/>
                                    {!isFmpAuthAndSave && <>
                                        <br/>We will charge the amount below when the
                                        <br/>loan starts, and we will place a temporary
                                        <br/>authorization on your card today.
                                    </>}
                                    {isFmpAuthAndSave && <>
                                        <br/>We will place an authorization of the
                                        <br/>amount below on your card today, and
                                        <br/>charge the card when your loan starts.
                                    </>}
                                    <br/>
                                    <span
                                    data-toggle="modal"
                                    data-target="#consumerCardCollectionLearnMoreModal"
                                    onClick={() => {
                                        logAmplitudeEvent("Opened Card Collection Learn More Modal", this.logProps);
                                    }}
                                    style={{display: "inline-block"}}>
                                        &nbsp;
                                    Learn more
                                    </span>
                                </p>
                            </div>
                        }
                    </PageHeader>
                    {showContent &&
                        <div className={firstMonthPaymentBoxClasses}>
                            <div className={firstMonthPaymentTitleClasses} style={{textAlign: "left"}}>
                                FIRST PAYMENT:
                            </div>
                            <div className={firstMonthPaymentAmountClasses}
                                 style={{textAlign: "right"}}>${this.props.selectedPlanAmount.toFixed(2)}</div>
                        </div>
                    }
                    <LoaderWithMessage isLoading={isLoading} duration={expectedRunLength}/>
                    {authAmount && <>
                        {this.isInIframe() ? (
                            <>
                                <div className={styles.content} style={{textAlign: "center"}}>
                                    <p style={{marginBottom: "30px"}}>
                                        A new window will pop up to collect your
                                        <br/>card details. Please allow popups through your
                                        <br/>browser.
                                    </p>
                                </div>
                                <button
                                    disabled={this.state && this.state.addDebitCardBtnDisabled}
                                    onClick={this.handleOnAddDebitCardClick}
                                    className={addDebitCardBtnClasses}
                                    data-test-id="submit-button">ADD DEBIT CARD
                                </button>
                            </>
                        ) : <CardCollectionForm/>}
                    </>
                    }
                </Container>
            </>
        );
    }
}

CardCollectionPage.propTypes = {
    getLoanStatus: PropTypes.func.isRequired,
    getAuthAmount: PropTypes.func.isRequired,
    getFMPConfig: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    serverSideError: state.consumer.errorMessage,
    status: state.consumer.status,
    token: state.consumer.token,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    expectedRunLength: state.consumer.expectedRunLength,
    selectedPlanAmount: state.consumer.selectedPlan.amount,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment,
    authAmount: state.consumer.authAmount,
    submitCardDataId: state.consumer.submitCardDataId,
    cardLastFourDigits: state.consumer.cardLastFourDigits,
    fmpConfig: state.consumer.fmpConfig,
});

export default connect(mapStateToProps, {sessionExpired, getLoanStatus, getAuthAmount, getFMPConfig})(
    CardCollectionPage
);
