import React, { useEffect } from "react";
import { withRouter } from "react-router";
import styles from "./LegalDocumentsStyles.module.scss";
import classNames from "classnames";
import logo from '@wisetack/assets/img/wisetack-logo.svg';
import TermsOfServiceBody from "@wisetack/shared-ui/components/agreements/TermsOfServiceBody";

const ConsumerTermsAgreementPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
      <div>
        <div className={styles.header}>
          <img className={styles.logo} src={logo} alt="wisetack-logo" />
        </div>
        <div className={classNames("modal-dialog", styles.modalDialog)}>
          <div className={classNames("modal-content", styles.modalContent)}>
            <TermsOfServiceBody styles={styles} />
          </div>
        </div>
      </div>
    );
};

export default withRouter(ConsumerTermsAgreementPage);