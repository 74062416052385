import React from "react";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";
import styles from "./PrequalPhoneForm.module.scss";
import {formatMobileNumber, validateMobileNumber} from "../utils/format";
import classNames from "classnames";

export function PrequalPhoneForm({requestInProgress, merchantProvidedPhoneNumber, onSubmit}) {
    const [mobileNumber, setMobileNumber] = React.useState(merchantProvidedPhoneNumber);

    const handleOnChange = e => {
        let value = e.target.value;
        if (value.length > 0) {
            value = value.replace(/[^0-9]/g, "");
            // see if first digit is a number.
            if (/[0-9]/.test(value[0])) {
                value = formatUsPhone(value.match(/[0-9]+/g).join(""));
            }
        }
        setMobileNumber(value);
    };

    let btnDisabled = requestInProgress || !validateMobileNumber(mobileNumber);

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });

    return <div style={{ marginTop: "15px"}}>
        <Form>
            <FormRow>
                <FormInput
                    type="text"
                    name="phone"
                    label="Mobile number"
                    value={mobileNumber}
                    onChange={handleOnChange}
                />
            </FormRow>
        </Form>
        {!requestInProgress &&
            <div className="row">
                <div className="col">
                    <button data-test-id="send-code" className={btnClasses} onClick={() => onSubmit(formatMobileNumber(mobileNumber))}>
                        <span className="material-icons" style={{ fontSize: "16px", marginRight: "5px", paddingBottom: "3px" }}>
                            lock_outline
                        </span>
                        SEND CODE
                    </button>
                    <div className={styles.message}>We’ll send you a verification code.</div>
                </div>
            </div>
        }
    </div>
}
