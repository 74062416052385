import React, {Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerDeclinedMaxPage.module.scss";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";
import image from "@wisetack/assets/img/icon-loan-limit.svg";
import classNames from "classnames";
import {getDocumentsContent} from "../store/actions/consumerActions";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";

const pageName = "Concurrent Loan Limit Page"
const documentNames = ['WisetackAdverseActionNotice.pdf','H5_CREDIT_SCORE_DISCLOSURE_DOCUMENT']

class ConsumerDeclinedMaxPage extends React.Component {

    isPayoutsListFound() {
        return this.props.payouts && this.props.payouts.length > 1
    }

    isOnePayoutFound() {
        return this.props.payouts && this.props.payouts.length === 1
    }

    getDocumentsToLoadEntityId() {
        let entityId = this.props.loanAppId;
        if (this.isOnePayoutFound()) {
            entityId = this.props.payouts[0].id;
        } else if (this.props.selectedPayout && this.props.selectedPayout.id) {
            entityId = this.props.selectedPayout.id;
        }
        if (!entityId || entityId === this.props.documentsEntityId) {
            return null;
        }
        return entityId
    }

    getDocumentsContent() {
        let entityId = this.getDocumentsToLoadEntityId();
        if (!!entityId) {
            this.props.getDocumentsContent(this.props.loanAppId, entityId, documentNames)
        }
    }

    isAADocumentFound() {
        return !!this.props.adverseAction && !this.props.isLoading && !this.getDocumentsToLoadEntityId()
    }

    isH5DocumentFound() {
        return !!this.props.h5CreditScoreDisclosure && !this.props.isLoading && !this.getDocumentsToLoadEntityId()
    }

    componentDidMount() {
        this.getDocumentsContent()
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
    }

    modalOnClick = eventType => () => {
        logAmplitudeEvent(eventType, this.logProps);
    };

    handleOnBack = () => {
        this.props.history.push("/loan_payouts");
    }

    render() {
        return (
            <Container>
                {this.isAADocumentFound() &&
                    <PdfModalZoom name="adverseActionModal" data={this.props.adverseAction} headerTitle="Adverse Action"/>
                }
                {this.isH5DocumentFound() &&
                    <PdfModalZoom name="h5CreditScoreDisclosureModal" data={this.props.h5CreditScoreDisclosure} headerTitle="Credit Score Disclosure"/>
                }
                <PageHeader progress="99%">
                    <div className={styles.title}>
                        Thanks for considering us again
                    </div>
                    <img className={styles.image} src={image} alt="loan-limit-logo" />
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    <p>
                        Unfortunately, we're not able to proceed, because you've reached your limit for financing through Wisetack.
                        {(this.isAADocumentFound() || this.isH5DocumentFound()) &&
                            " We've emailed you the following documents with more information."
                        }
                    </p>
                    <p>
                        {this.isAADocumentFound() &&
                            <span data-toggle="modal" data-target="#adverseActionModal"
                                  onClick={this.modalOnClick("Opened Notice of Action Link")}>
                                Notice of Action
                            </span>
                        }
                        <br/>
                        {this.isH5DocumentFound() &&
                            <span data-toggle="modal" data-target="#h5CreditScoreDisclosureModal"
                                  onClick={this.modalOnClick("Opened Credit Score Disclosure (H5) Link")}>
                                Credit Score Disclosure
                            </span>
                        }
                    </p>
                    <LoaderWithMessage isLoading={this.props.isLoading}/>
                    <Error pageName={pageName}>{this.props.errorMessage}</Error>
                    <p>Thank you for being a valued customer. We hope to serve you again in the future.</p>
                    { this.isPayoutsListFound() && !this.props.isLoading &&
                        <button
                            data-test-id="payout-declined-back"
                            className={classNames("btn", styles.buttonEnabled)}
                            onClick={this.handleOnBack}
                        >
                            BACK TO PAYOUTS
                        </button>
                    }
                </div>
            </Container>
        );
    }
}

ConsumerDeclinedMaxPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    status: state.consumer.status,
    payouts: state.consumer.payouts,
    selectedPayout: state.consumer.selectedPayout,
    adverseAction: state.consumer.adverseAction,
    h5CreditScoreDisclosure: state.consumer.h5CreditScoreDisclosure,
    documentsEntityId: state.consumer.documentsEntityId,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage
});

export default connect(
  mapStateToProps,
  {getDocumentsContent}
)(ConsumerDeclinedMaxPage);