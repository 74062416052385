import React from "react";
import styles from "./CitizensPayoutReview.module.scss";
import {formatAmount, formatAPR} from "@wisetack/shared-ui/utils/format";

export const CitizensPayoutReview = ({numberOfPayments, amountOfPayments, apr}) => {

    return <div className={styles.mainContainer}>
        <div className={styles.legend}>{numberOfPayments} monthly payments of</div>
        <div className={styles.title}>{formatAmount(amountOfPayments)}</div>
        <div className={styles.apr}>{formatAPR(apr)}</div>
    </div>
}