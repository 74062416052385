"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddressSuggestions = exports.CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _autocompleteSamples = require("./autocompleteSamples");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = "CONSUMER_SMARTY_STREETS_AUTOCOMPLETE";
exports.CONSUMER_SMARTY_STREETS_AUTOCOMPLETE = CONSUMER_SMARTY_STREETS_AUTOCOMPLETE;
var cache = {
  '': []
};
var getAddressSuggestions = function getAddressSuggestions(address, maxResults, loadingFnStop) {
  return function (dispatch) {
    var _window$_wtenv_, _window$_wtenv_2;
    var callback = function callback(response, autocompleteAvailable) {
      dispatch({
        type: CONSUMER_SMARTY_STREETS_AUTOCOMPLETE,
        payload: response.data
      });
      loadingFnStop(autocompleteAvailable);
    };
    dispatch({
      type: CONSUMER_SMARTY_STREETS_AUTOCOMPLETE,
      payload: {
        suggestions: []
      }
    });
    if (!((_window$_wtenv_ = window._wtenv_) !== null && _window$_wtenv_ !== void 0 && _window$_wtenv_.REACT_APP_SMARTY_STREETS_AUTOCOMPLETE_KEY)) {
      var samples = (0, _autocompleteSamples.getAutocompleteSamples)(address);
      callback({
        data: samples
      }, true);
      return;
    }
    if (cache[address]) {
      callback({
        data: {
          suggestions: cache[address]
        }
      }, true);
      return;
    }
    _axios["default"].get('https://us-autocomplete-pro.api.smartystreets.com/lookup' + '?key=' + ((_window$_wtenv_2 = window._wtenv_) === null || _window$_wtenv_2 === void 0 ? void 0 : _window$_wtenv_2.REACT_APP_SMARTY_STREETS_AUTOCOMPLETE_KEY) + '&max_results=' + maxResults + '&search=' + address, {
      transformRequest: function transformRequest(data, headers) {
        delete headers.common;
        return data;
      }
    }).then(function (value) {
      if (value.data.suggestions) {
        cache[address] = value.data.suggestions;
      } else {
        cache[address] = [];
      }
      callback(value, true);
    })["catch"](function (reason) {
      callback({
        data: {
          suggestions: []
        }
      }, reason.response.status !== 402);
    });
  };
};
exports.getAddressSuggestions = getAddressSuggestions;