"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initDatadog = void 0;
var _browserRum = require("@datadog/browser-rum");
var initDatadog = function initDatadog(serviceName) {
  var _window$_wtenv_;
  if ((_window$_wtenv_ = window._wtenv_) !== null && _window$_wtenv_ !== void 0 && _window$_wtenv_.DATADOG_APPLICATION_ID) {
    var _window$_wtenv_2, _window$_wtenv_3, _window$_wtenv_4, _window$_wtenv_5, _window$_wtenv_6;
    _browserRum.datadogRum.init({
      applicationId: (_window$_wtenv_2 = window._wtenv_) === null || _window$_wtenv_2 === void 0 ? void 0 : _window$_wtenv_2.DATADOG_APPLICATION_ID,
      clientToken: (_window$_wtenv_3 = window._wtenv_) === null || _window$_wtenv_3 === void 0 ? void 0 : _window$_wtenv_3.DATADOG_CLIENT_TOKEN,
      allowedTracingOrigins: [(_window$_wtenv_4 = window._wtenv_) === null || _window$_wtenv_4 === void 0 ? void 0 : _window$_wtenv_4.REACT_APP_API_URL],
      service: serviceName,
      env: (_window$_wtenv_5 = window._wtenv_) === null || _window$_wtenv_5 === void 0 ? void 0 : _window$_wtenv_5.DD_ENV,
      version: (_window$_wtenv_6 = window._wtenv_) === null || _window$_wtenv_6 === void 0 ? void 0 : _window$_wtenv_6.DD_VERSION,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask',
      telemetrySampleRate: 0,
      useSecureSessionCookie: true
    });
    _browserRum.datadogRum.startSessionReplayRecording();
  }
};
exports.initDatadog = initDatadog;