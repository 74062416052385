"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = simulateMouseClick;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function simulateMouseClick(element) {
  if (!element) {
    return;
  }
  var mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  mouseClickEvents.forEach(function (mouseEventType) {
    return element.dispatchEvent(new MouseEvent(mouseEventType, {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1
    }));
  });
}