import PropTypes from "prop-types";
import moment from "moment";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import styles from "./ConsumerConfirmationPage.module.scss";
import classNames from "classnames";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormDatepickerFlatpickrInput from "@wisetack/shared-ui/components/FormDatepickerFlatpickrInput";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator"
import {submitData} from "../store/actions/consumerActions";
import uuid from "uuid";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import {getVerticalFragment} from "../utils/verticalMessages";

const formFields = [
  "serviceCompletionProvidedByBorrower",
];
const pageName = "Consumer Confirmation Job Not Done Page"

class ConsumerConfirmationDeniedPastPage extends React.Component {
  state = {
    serviceCompletionProvidedByBorrower: null,
    errors: {}
  };

  constructor(props) {
    super(props);
    this.validator = new BorrowerFieldValidator(pageName);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    logAmplitudeEvent(
      "Confirm Later Page",
      {
        loanApplicationId: this.props.loanAppId
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (!!this.state.proceedWithoutDate
        || (this.props.submitDataRequestId
            && this.props.submitDataRequestId !== prevProps.submitDataRequestId)
    ) {
      this.props.history.push("/purchase_confirm_reminder");
    }
  }

  setPayload = (payload, name) => {
    if (name === "serviceCompletionProvidedByBorrower") {
      if (this.state.serviceCompletionProvidedByBorrower
          && this.state.serviceCompletionProvidedByBorrower.constructor !== Object) {
        payload[name] = `${moment(this.state.serviceCompletionProvidedByBorrower).format('YYYY-MM-DD')}`;
      }
    }
  };

  validateScheduledCompletionDate(date, loanAppExpirationDate) {
    const errorType = this.validator.validateScheduledCompletionDate(date, loanAppExpirationDate);
    if (errorType === 'INVALID_PAST_SCHEDULED_COMPLETION_DATE') return getVerticalFragment('completion_error_past', this.props.vertical);
    if (errorType === 'INVALID_PAST_TO_OFFER_COMPLETION_DATE') {
      return getVerticalFragment('completion_error_expired', this.props.vertical);
    }
    return errorType;
  }

  handleOnChange = e => {
    let name = e.target.name;

    this.setState({ [name]: e.target.value });
    this.setState({ fieldsChanged: { ...this.state.fieldsChanged, [name]: true } });

    const error = this.validateScheduledCompletionDate(e.target.value, this.props.loanAppExpirationDate);
    this.setError("serviceCompletionProvidedByBorrower", error);
  };

  handleOnButtonClick = () => {
    const payload = {};
    formFields.forEach(name => this.setPayload(payload, name));
    logAmplitudeEvent(
        "Pressed Confirm Later Button",
        {
          loanApplicationId: this.props.loanAppId,
          formFields: payload
        }
    );
    if (Object.keys(payload).length !== 0) {
      this.props.submitData(this.props.loanAppId, payload, uuid.v4());
    } else {
      this.setState({
        proceedWithoutDate: true
      });
    }
  };

  handleOnEnterPress = event => {
    event.preventDefault();
    if (this.state.reviewed && event.key === 'Enter') {
      this.handleOnButtonClick();
    }
  }

  isButtonDisabled = () => {
    for (const key in this.state.errors) {
      if (this.state.errors[key]) return true;
    }
    return false;
  };

  getEditValue = name => {
    if (this.state[name]) {
      return this.state[name];
    }
    if (this.props.fieldsValue && this.props.fieldsValue[name]) {
      return this.props.fieldsValue[name];
    }
    return "";
  };

  setError(name, error) {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: error
      }
    });
  }

  render() {
    const {
      isLoading,
      doingBusinessAs,
      loanAppExpirationDate,
      merchantName,
    } = this.props;

    let btnDisabled = this.isButtonDisabled();

    const btnClasses = classNames({
      btn: true,
      "btn-block": true,
      "btn-disabled": btnDisabled,
      [styles.buttonDisabled]: btnDisabled,
      [styles.buttonEnabled]: !btnDisabled
    });

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
          <div>
            <Loader />
            <div className={styles.message}>Wait a moment please...</div>
          </div>
      );
    };

    return (
      <Container>
        {!isLoading ? (
          <>
            <PageHeader progress="85%">
              {getVerticalFragment('confirm_later_header', this.props.vertical)}
              <Fragment />
            </PageHeader>
            <div className={styles.content}>
              {getVerticalFragment('confirm_later_message', this.props.vertical, {
                merchantName: doingBusinessAs ? doingBusinessAs : merchantName
              })}
              <Fragment>
                <Form>
                  <FormRow>
                    <FormDatepickerFlatpickrInput
                        name="serviceCompletionProvidedByBorrower"
                        completedOnDate = {this.state.serviceCompletionProvidedByBorrower}
                        label={getVerticalFragment('scheduled_completion_label', this.props.vertical)}
                        onChange={this.handleOnChange}
                        onFocus={() => {
                          const error = this.validateScheduledCompletionDate(this.getEditValue("serviceCompletionProvidedByBorrower"), loanAppExpirationDate);
                          this.setError("serviceCompletionProvidedByBorrower", error);
                        }}
                        onBlur={() => {
                          const error = this.validateScheduledCompletionDate(this.getEditValue("serviceCompletionProvidedByBorrower"), loanAppExpirationDate);
                          this.setError("serviceCompletionProvidedByBorrower", error);
                        }}
                        fieldsError={this.props.fieldsError}
                        skipValidation={true}
                    />
                    <p style={{color: "#FE6E65"}}>
                      { !!this.state.errors['serviceCompletionProvidedByBorrower'] && this.state.errors['serviceCompletionProvidedByBorrower']}
                    </p>
                  </FormRow>
                </Form>
                <Form>
                  <FormRow>
                    <div className="col">
                      <button
                          data-test-id="continue"
                          className={btnClasses}
                          onClick={this.handleOnButtonClick}
                          onKeyPress={this.handleOnEnterPress}
                      >
                        CONFIRM LATER
                      </button>
                    </div>
                  </FormRow>
                </Form>
              </Fragment>
            </div>
          </>
        ) : (
          <>
            <PageHeader progress="90%">
              <Fragment />
              <Fragment />
            </PageHeader>
            <LoaderWithMessage />
          </>
        )}
      </Container>
    );
  }
}

ConsumerConfirmationDeniedPastPage.propTypes = {
  submitData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.consumer.isLoading,
  doingBusinessAs: state.consumer.doingBusinessAs,
  firstName: state.consumer.firstName,
  loanAppExpirationDate: state.consumer.loanAppExpirationDate,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  selectedPlan: state.consumer.selectedPlan,
  serviceCompletionDate: state.consumer.serviceCompletionDate,
  status: state.consumer.status,
  submitDataRequestId: state.consumer.submitDataRequestId,
  messages: state.consumer.messages,
  vertical: state.consumer.vertical
});

export default connect(mapStateToProps, {submitData})(ConsumerConfirmationDeniedPastPage);
