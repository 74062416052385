import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerPurchaseCompletePage.module.scss";
import image1 from "@wisetack/assets/img/check-mark.svg";
import moment from "moment";
import {getTruthInLending} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";

const pageName = "Purchase Complete Lock Page"

class ConsumerPurchaseCompleteLockPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        logAmplitudeEvent(pageName, {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        });

        if (
            !this.props.selectedPlan ||
            Object.keys(this.props.selectedPlan).length === 0
        ) {
            this.props.getTruthInLending(this.props.loanAppId, this.props.offerId, false);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        }
        if (this.props.errorMessage) {
            Log.info(this.props.errorMessage, `error`);
            this.props.history.push("/error");
        }
    }

    render() {
        const {
            isLoading,
            errorMessage,
            merchantName,
            loanAppExpirationDate
        } = this.props;

        let expDate = "";
        const eDate = moment(loanAppExpirationDate, "MM/DD/YYYY");
        if (eDate.isValid()) {
            expDate = eDate.format("LL");
        }

        const LoaderWithMessage = () => {
            if (!isLoading) return null;
            return (
                <div>
                    <Loader/>
                    <div className={styles.message}>Wait a moment please...</div>
                </div>
            );
        };

        const showContent = !isLoading && !errorMessage;

        return (
            <Container>
                <LearnMoreModal/>
                <PageHeader progress="100%">
                    {showContent ? <Fragment>You're all set</Fragment> : null}
                    <Fragment/>
                </PageHeader>
                <LoaderWithMessage/>
                <Error pageName={pageName}>{errorMessage}</Error>
                {showContent ? (
                    <>
                        <img className={styles.image} src={image1} alt="check-mark"/>
                        <div className="row" style={{marginBottom: "5px"}}>
                            <div className="col">
                                <div className={styles.next}>
                                    <p>
                                        Before your loan can start, <b>{merchantName || "merchant"}</b> just needs to confirm on their end.
                                    </p>
                                    <p>
                                        If they don't confirm by <b>{expDate || "expiration date"}</b>, your loan application will be canceled.
                                        Don't worry - this won't impact your credit, and you can pay the merchant by another method.
                                    </p>
                                    <p>
                                        We'll let you know when your loan is ready to go.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{paddingBottom: "15px"}}/>
                )}
            </Container>
        );
    }
}

ConsumerPurchaseCompleteLockPage.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
    selectedPlan: state.consumer.selectedPlan,
    offerId: state.consumer.selectedLoanOfferId || state.consumer.offerId,
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage,
    initExpired: state.consumer.initExpired
});

export default connect(
    mapStateToProps,
    {getTruthInLending}
)(ConsumerPurchaseCompleteLockPage);
