import uuid from "uuid";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import styles from "./ConsumerConfirmationPage.module.scss";
import classNames from "classnames";
import {confirmPurchase, getOffers, setError} from "../store/actions/consumerActions";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import moment from "moment";
import PurchaseConfirmationLoanOffer from "@wisetack/shared-ui/components/PurchaseConfirmationLoanOffer";
import PurchaseConfirmationLearnMore from "./PurchaseConfirmationLearnMore";
import {getVerticalFragment} from "../utils/verticalMessages";

const moreThanOne = (items) => {
  return !!items && items.length > 1
}

const pageName = "Purchase Confirmation Page";

class ConsumerConfirmationPage extends React.Component {

  state = {}

  payoutsRedirect = () => {
    if (moreThanOne(this.props.payoutsList) || moreThanOne(this.props.payouts)) {
      this.props.history.push("/loan_payouts");
      return true;
    }
    return false;
  }

  verifyPhoneRedirect = () => {
    if (!!this.props.fieldsRequired && this.props.fieldsRequired.linked_phone) {
      this.props.history.push("/phone_return");
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.payoutsRedirect() || this.verifyPhoneRedirect()) {
      return;
    }
    window.scrollTo(0, 0);
    if (!this.props.selectedPlan || !this.props.selectedPlan.status) {
      // page reloaded
      if (!this.props.loanAppId) {
        this.props.history.push("/error");
        this.props.setError('Please use your payment link to log in.')
      } else {
        this.props.getOffers(this.props.loanAppId);
      }
      return;
    }
    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName,
    };
    logAmplitudeEvent(pageName, this.logProps);
  }

  handleYesClick = () => {
    const requestId = uuid.v4();
    logAmplitudeEvent("Work Complete", this.logProps);
    this.props.confirmPurchase(this.props.loanAppId, null, "YES", requestId);
  };

  handleNoClick = () => {
    const requestId = uuid.v4();
    logAmplitudeEvent("Work Not Complete", this.logProps);
    this.props.confirmPurchase(this.props.loanAppId, null, "NO", requestId);
  };

  componentDidUpdate(prevProps) {
    if (this.payoutsRedirect() || this.verifyPhoneRedirect()) {
      return;
    }
    if (
      this.props.status === "OFFER_CONVERTED" ||
      this.props.status === "PAYOUTS_CONVERTED" ||
      this.props.status === "SETTLED"
    ) {
      if (this.props.lockRequired) {
        this.props.history.push("/purchase_complete_lock");
      } else {
        this.props.history.push("/purchase_complete");
      }
      return;
    }
    if (
      this.props.confirmationRequestId &&
      this.props.confirmationRequestId !== prevProps.confirmationRequestId
    ) {
      if(this.props.emailVerificationRequired === true){
        this.props.history.push("/email_confirm");
      } else {
        this.props.history.push("/purchase_confirm_pending");
      }
      return;
    }
    if (this.props.status === 'CANCELED') {
      this.props.history.push('/purchase_canceled')
      return;
     }
  }

  render() {
    const {
      isLoading,
      merchantName,
      selectedPlan,
      loanAppExpirationDate,
      settlementDelay,
    } = this.props;

    const btnClasses = classNames({
      btn: true,
      "btn-disabled": false,
      [styles.buttonEnabled]: true,
    });

    const btnClassesInverse = classNames({
      btn: true,
      "btn-disabled": false,
      [styles.buttonEnabledInverse]: true,
    });

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
        <div>
          <Loader />
          <div className={styles.message}>Wait a moment please...</div>
        </div>
      );
    };

    return (
      <Container>
        {!isLoading ?
          <>
            <PageHeader progress="85%">
              {getVerticalFragment('confirmation_header', this.props.vertical)}
              <Fragment />
            </PageHeader>
            <div className={styles.content}>
              <p>
                {getVerticalFragment(
                    'confirmation_message',
                    this.props.vertical,
                    {
                      merchantName,
                      approvedAmount: formatAmount(selectedPlan.approvedAmount),
                      settlementDelay: settlementDelay,
                    }
                )
                }
                <span
                    data-toggle="modal"
                    data-target="#purchaseConfirmationLearnMore"
                    onClick={() => {
                      logAmplitudeEvent("Clicked Purchase Confirmation Learn More", this.logProps);
                    }}
                >
                  Learn More
                </span>
              </p>
              <p>
                  <span
                      data-toggle="modal"
                      data-target="#purchaseConfirmationLoanOffer"
                      onClick={() => {
                        logAmplitudeEvent("Clicked Purchase Confirmation Loan Offer", this.logProps);
                      }}
                  >
                    Your offer
                  </span>{" "}
                is valid until <strong>{moment(loanAppExpirationDate, "MM DD YYYY").format("LL")}</strong>.
              </p>
              <div style={{ textAlign: "center" }}>
                <button
                  data-test-id="purchase-confirm-yes"
                  className={btnClasses}
                  onClick={ () => {
                    this.handleYesClick();
                  }}
                >
                  {getVerticalFragment('release_payment_button', this.props.vertical)}
                </button>
                <br />
                <button style={{ border: "1.5px solid #07C0CA" }}
                  data-test-id="purchase-confirm-no"
                  className={btnClassesInverse}
                  onClick={this.handleNoClick}
                >
                  {getVerticalFragment('confirm_later_button', this.props.vertical)}
                </button>
              </div>
              <PurchaseConfirmationLearnMore loanAppExpirationDate={loanAppExpirationDate}
                                             vertical={this.props.vertical}
                                             settlementDelay={this.props.settlementDelay}
              />
              <PurchaseConfirmationLoanOffer selectedPlan={this.props.selectedPlan} loanAppExpirationDate={loanAppExpirationDate}/>
            </div>
        </> :
        <>
          <PageHeader progress="85%">
            <Fragment />
            <Fragment />
          </PageHeader>
          <LoaderWithMessage />
        </>
        }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmationRequestId: state.consumer.confirmationRequestId,
  firstName: state.consumer.firstName,
  isLoading: state.consumer.isLoading,
  loanAppExpirationDate: state.consumer.loanAppExpirationDate,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  selectedLoanOfferId: state.consumer.selectedLoanOfferId,
  selectedPlan: state.consumer.selectedPlan,
  serviceCompletionDate: state.consumer.serviceCompletionDate,
  loanServicer: state.consumer.loanServicer,
  status: state.consumer.status,
  transactionAmount: state.consumer.transactionAmount,
  emailVerificationRequired: state.consumer.emailVerificationRequired,
  lockRequired: state.consumer.lockRequired,
  offerLock: state.consumer.offerLock,
  vertical: state.consumer.vertical,
  payouts: state.consumer.payouts,
  payoutsList: state.consumer.payoutsList,
  settlementDelay: state.consumer.settlementDelay,
  fieldsRequired: state.consumer.fieldsRequired,
  cardLastFourDigits: state.consumer.cardLastFourDigits
});

export default connect(mapStateToProps, {confirmPurchase, getOffers, setError})(
    ConsumerConfirmationPage
);
