import React from "react";
import styles from "./CitizensSummaryTable.module.scss";

export function CitizensSummaryTable({schumerBoxAPR, schumerBoxAnnualFee, schumerBoxPenaltyFee}) {
    // -- Render control
    return<><div className={styles.mainContainer}>
        <div className={styles.mainTitle}> Line of Credit Account Summary Table</div>
        <div className={styles.content}><b>Promotional Rates on the Account: </b>Purchases may qualify for promotional pricing ("Promotional Offers"). Promotional
        Offers may differ by term and/or product and may be based
        on creditworthiness. If your purchase qualifies, the
        Promotional Offer applies to your purchase until paid in
        full. If your purchase does not qualify for a Promotional
        Offer, the APR listed below will apply. See your Line of
        Credit Agreement for more details.</div>
        <table className={styles.table}>
            <tbody>
                <tr className={styles.tableRow}>
                    <td className={styles.tableCellBold} colSpan="2">Interest Rate and Interest Charges</td>
                </tr>
                <tr>
                    <td className={styles.tableCell}>Annual Percentage Rates (APR) for Purchases</td>
                    <td className={styles.tableCell}><span className={styles.apr}>{schumerBoxAPR}</span> Standard Purchase (non-promotional) APR. APRs for future purchases may vary up to the Standard Purchase APR.</td>
                </tr>
                <tr>
                    <td className={styles.tableCell}>How to Avoid Paying Interest on Purchases</td>
                    <td className={styles.tableCell}>Your due date is at least 21 days after the close of each billing cycle. We will not charge you interest on your purchase if you pay the entire balance by the payment due date in your first billing statement for that purchase.</td>
                </tr>
            </tbody>
        </table>
        <br/>
        <table className={styles.table}>
            <tbody>
                <tr className={styles.tableRow}>
                    <td className={styles.tableCellBold} colSpan="2">Fees</td>
                </tr>
                <tr>
                    <td className={styles.tableCell}>Annual Fee</td>
                    <td className={styles.tableCell}>{schumerBoxAnnualFee}</td>
                </tr>
                <tr>
                    <td className={styles.tableCell}>Penalty Fees</td>
                    <td className={styles.tableCell}>{schumerBoxPenaltyFee}</td>
                </tr>
            </tbody>
        </table>
        <br/>
        <div className={styles.content}>
            How We Will Calculate Your Balance: We use a method called
            “average daily balance (including new purchases).” See your
             account agreement for more details
            <br/>
            Billing Rights: Information on your rights to dispute
            transactions and how to exercise those rights is provided in
            your account agreement.
        </div>
    </div>
    </>
}
 /*"schumerBoxAPR": "29.99%",
        "schumerBoxAnnualFee": "$38",
        "schumerBoxPenaltyFee": "$38",*/

