import React from "react";
import classNames from "classnames";
import styles from "./ConfirmPayoutsModal.module.scss";
import image from "@wisetack/assets/img/info.svg";
import moment from "moment";

const ConfirmPayoutsModal = ({expirationDate}) => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="learnMoreConfirmPayoutsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`learnMoreConfirmPayoutsModalLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className={classNames("modal-header", styles.modalHeader)}>
                        <img src={image} alt="logo"/>
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-learnMoreConfirmPayoutsModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <h1>Confirming & releasing payouts</h1>
                        <p>
                            The next step is to let us know that you’ve agreed to the merchant’s services.
                        </p>
                        <p>
                            Once you confirm this, we release the payout to the merchant and your financing will begin.
                        </p>
                        <p>
                            Your financing offer is valid until <strong>{!!expirationDate && moment(expirationDate, "MM DD YYYY").format("LL")}</strong>. If you don’t confirm by then, you can reapply to get a new offer.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPayoutsModal;
