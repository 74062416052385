import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";

import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";
import {CONSUMER_ERROR} from "../store/actions/types";

export const isActiveLoansFound = (activeLoans) => {
    if (!activeLoans || !activeLoans.loanInfoList) {
        return false;
    }
    return activeLoans.loanInfoList.length > 0;
}

export default function useDashboardFlow(pageName) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const loanAppId = useSelector(state => state.consumer.loanAppId);
    const merchantId = useSelector(state => state.consumer.merchantId);
    const token = useSelector(state => state.consumer.token);
    const status = useSelector(state => state.consumer.status);
    const activeLoans = useSelector(state => state.consumer.activeLoans);
    const activeLoansFound = isActiveLoansFound(activeLoans);
    const activeLoanList = !!activeLoansFound && activeLoans.loanInfoList;

    const logAmplitude = (eventType, eventProperties) => {
        const loanId = loanAppId ? loanAppId.substr(0, 8) : null
        let evProps = {
            pageName,
            merchantId,
            loanId,
            token,
            status,
            path: location.pathname
        }
        if (eventProperties) {
            evProps = {
                ...evProps,
                ...eventProperties
            }
        }
        if (!eventType) {
            eventType = pageName
        }
        logAmplitudeEvent(eventType, evProps);
    }

    const navigateEnterPage = () => {
        let initToken = !!token ? token : loadToken();
        if (initToken) {
            history.push("/" + initToken)
        } else {
            dispatch({ type: CONSUMER_ERROR, payload: "Please use your payment link to log in."});
            history.push("/error")
        }
    }

    useEffect(() => {
        if (!token || (location.pathname === '/dashboard' && !activeLoansFound)) {
            navigateEnterPage();
            return;
        }
        logAmplitude();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const direct = (initToken = token) => {
        const path = `/${initToken}?direct`
        logAmplitude("Direct navigate", {newPath: path})
        history.push(path)
    }

    return {history, loanAppId, token, status, activeLoanList, direct, logAmplitude}
}
