// this screen is not used, was removed from new UI version, left if flow will be changed back
import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";

import {prequalGet, prequalPatch} from "../store/actions/consumerActions";
import styles from "./PrequalReapplyPage.module.scss";
import image from "@wisetack/assets/img/hello.svg";
import {PrequalInfoPageBottom} from "../components/PrequalInfoPageBottom";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";
import usePrequalFlow from "../hooks/usePrequalFlow";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import PrequalUpdateInfoModal from "./PrequalUpdateInfoModal";

const pageName = 'Prequal Reapply Page'

function PrequalReapplyPage({prequalGet, prequalPatch}) {

    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const status = useSelector(state => state.consumer.status);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const {applicationId, logAmplitude} = usePrequalFlow(prequalGet, newRequest);

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    const handleSubmit = () => {
        prequalPatch(
            applicationId,
            {reapply: true},
            newRequest()
        );
        logAmplitude(pageName, 'Reapply requested')
    }

    return <Container>
        <PageHeader progress="0%">
            <div>Welcome back!</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="hello-logo"/>
        <div className={styles.message}>
            {status === 'EXPIRED' ? 'Your previous prequalification has expired. Continue to check your new eligibility.' : 'Are you considering applying for another loan? Continue to check your new eligibility.'}
        </div>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        { applicationId && !requestInProgress &&
            <>
                <PrequalInfoPageBottom
                    disableLast={true}
                    display={true}
                    onButtonClick={handleSubmit}
                />
                <div
                    className={styles.buttonLink}
                    data-toggle="modal"
                    data-target="#prequalUpdateInfoModal"
                >
                    Need to update info?
                </div>
            </>
        }
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
        <PrequalUpdateInfoModal/>
    </Container>
}

export default connect(null, {prequalGet, prequalPatch})(
    PrequalReapplyPage
);
