import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from 'yup';
import classNames from "classnames";
import faker from "faker/locale/en_US";

import Container from "@wisetack/shared-ui/components/Container";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import FormNumberInput from "@wisetack/shared-ui/components/FormNumberInput";
import FormDateInput from "@wisetack/shared-ui/components/FormDateInput";
import TermsOfService from "@wisetack/shared-ui/components/TermsOfService";
import PrivacyPolicyModal from "@wisetack/shared-ui/components/PrivacyPolicyModal";
import ElectronicDisclousersModal from "@wisetack/shared-ui/components/ElectronicDisclosuresModal";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";
import {formatCurrency} from "@wisetack/shared-ui/utils/format";
import {PrequalInfoPageHeader} from "../components/PrequalInfoPageHeader";
import {PrequalInfoPageBottom} from "../components/PrequalInfoPageBottom";
import styles from "./ConsumerEntryPage.module.scss";
import usePrequalFlow from "../hooks/usePrequalFlow";
import {
    neuroIdSetSessionStarted,
    prequalGet,
    prequalPatch,
    submitCheckboxSelection
} from "../store/actions/consumerActions";
import IncomeInfoModal from "./IncomeInfoModal";
import {NeuroID} from "@wisetack/shared-ui/utils/neuroid/NeuroID";
import ConsumerEntryPageMoreInfoModal from "@wisetack/shared-ui/components/ConsumerEntryPageMoreInfoModal";

const pageName = "Prequal Entry Page";
let incomeHelp = false;

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Enter first name'),
    lastName: Yup.string().required('Enter last name'),
    email: Yup.string().email('Please enter a valid email address').required('Enter email address'),
    day: Yup.string().required('Enter date of birth'),
    month: Yup.string().required('Enter date of birth'),
    year: Yup.string().required('Enter date of birth').matches(/(^\d{4}$)/, {message: 'Please enter a valid date of birth'}),
    annualIncomeBeforeTaxes: Yup.string().required('Enter income').test('minimum-income', 'Yearly income is below the minimum required.', (value) => value && value.split('.')[0].replace(/\D/g, "") >= 10000),
    ssn4: Yup.string().required('Enter last 4 SSN').matches(/(^\d{4}$)/, {message: 'SSN4 must be exactly 4 digits'})
})

function PrequalEntryPage({prequalPatch, prequalGet, submitCheckboxSelection, neuroIdSetSessionStarted}) {
    const status = useSelector(state => state.consumer.status);
    const personalDataProvided = useSelector(state => state.consumer.personalDataProvided);
    const parentId = useSelector(state => state.consumer.parentId);
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const fieldsValue = useSelector(state => state.consumer.fieldsValue);
    //const prefilledCount = useSelector(state => state.consumer.prefilledCount);
    //const fieldSource = useSelector(state => state.consumer.fieldsSource.firstName);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const {applicationId, logAmplitude} = usePrequalFlow(prequalGet, newRequest);
    const [reviewed, setReviewed] = React.useState(null);

    const patchRequired = !!applicationId && !!parentId && personalDataProvided && status === 'PENDING'

    useEffect(() => {
        logAmplitude(pageName,null,{});
        const neuroID = NeuroID.getInstance()
        neuroID.start(pageName)
        neuroID.identify(applicationId)
        neuroIdSetSessionStarted(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    // this effect is required to send patch request to create offers when borrower profile used
    useEffect(
        () => {
            if (patchRequired) {
                prequalPatch(
                    applicationId,
                    {},
                    newRequest()
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [applicationId, patchRequired]
    );


    const handleOnSave = (values) => {
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            annualIncomeBeforeTaxes: values.annualIncomeBeforeTaxes.split('.')[0].replace(/\D/g, ""),
            ssn4: values.ssn4,
            dob: `${values.year}-${values.month.padStart(2, '0')}-${values.day.padStart(2, '0')}`
        };
        if (reviewed) {
            data.termsOfServiceAccepted = true;
            data.electronicDisclosuresAccepted = true;
            data.privacyPolicyAccepted = true;
        }
        prequalPatch(
            applicationId,
            data,
            newRequest()
        );
        logAmplitude(pageName, 'Personal data submitted');
    }

    const formik = useFormik({
        initialValues: {
            month: "",
            day: "",
            year: "",
            ...fieldsValue
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleOnSave
    });


    return <Container>
        <PrequalInfoPageHeader progress="33%"/>
        {   !patchRequired &&
            <Form>
                <FormRow>
                    <FormInput
                        name="firstName"
                        label="First name"
                        formik={formik}
                        blacklist={/[^a-zA-Z.\-'\s]/g}
                        autoFill={faker.name.firstName}
                        autoFocus
                        data-neuro-label="firstName"
                    />
                    <FormInput
                        name="lastName"
                        label="Last name"
                        formik={formik}
                        blacklist={/[^a-zA-Z.\-'\s]/g}
                        autoFill={faker.name.lastName}
                        data-neuro-label="lastName"
                    />
                </FormRow>
                <FormRow>
                    <FormInput
                        type="email"
                        name="email"
                        label="Email"
                        formik={formik}
                        autoFill='test+borrower@wisetack.com'
                        data-neuro-label="email"
                    />
                </FormRow>
                <FormRow>
                    <FormDateInput
                        name="dob"
                        label="Date of birth"
                        formik={formik}
                        data-neuro-label="dob"
                    />
                </FormRow>
                <FormRow>
                    <FormNumberInput
                        type="text"
                        min="0"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="annualIncomeBeforeTaxes"
                        label="Annual income"
                        value={formik.values["annualIncomeBeforeTaxes"]}
                        onChange={(e) => {
                            if (e.target.value === '~') {
                                e.target.value = formatCurrency(faker.finance.amount(10000, 200000));
                            }
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        errors={formik.touched["annualIncomeBeforeTaxes"] && formik.errors}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        allowNegative={false}
                        data-neuro-label="income"
                    />
                </FormRow>
                {incomeHelp && <div
                    className={styles.buttonLink}
                    data-toggle="modal"
                    data-target="#incomeInfoModal"
                >
                <span className={classNames("material-icons", styles.infoIcon)}>
                    info_outline
                </span>
                    What is this?
                </div>}
                <FormRow>
                    <FormInput
                        type="text"
                        min="0"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="ssn4"
                        label="Last 4 SSN"
                        formik={formik}
                        blacklist={/\D/g}
                        max={4}
                        autoFill="4444"
                        data-neuro-label="ssnLast4"
                    />
                </FormRow>
                <FormRow>
                    {" "}
                    <div className={styles.footer}>
                        <div className="row">
                            <div className="col-1" style={{ minWidth: "40px" }}>
                                <div
                                    role="dialog"
                                    data-test-id="reviewed"
                                    className={styles.box}
                                    onClick={() => {
                                        const rev = !reviewed;
                                        submitCheckboxSelection(applicationId, "DATA_COLLECTION_PAGE", rev);
                                        setReviewed(rev)
                                        logAmplitude(pageName, 'Checkbox clicked', {value: rev});
                                    }
                                    }
                                >
                                    {reviewed && (
                                        <span
                                            className="material-icons"
                                            style={{
                                                fontSize: "25px",
                                                paddingLeft: "1px",
                                                paddingTop: "1px"
                                            }}
                                        >
                              done
                            </span>
                                    )}
                                </div>
                            </div>
                            <div className={classNames("col", styles.reviewed)}>
                                I agree to the{" "}
                                <span
                                    data-toggle="modal"
                                    data-target="#tos"
                                    onClick={() => {
                                        logAmplitude(pageName, "Clicked Terms of Service Link");
                                    }}
                                >
                          Terms of Service
                        </span>
                                {", "}
                                <span
                                    data-toggle="modal"
                                    data-target="#eDisclouser"
                                    onClick={() => {
                                        logAmplitude(pageName, "Clicked E-Sign Consent Link");
                                    }}
                                >
                          E-Sign Consent
                        </span>
                                , and{" "}
                                <span
                                    data-toggle="modal"
                                    data-target="#privacyPolicy"
                                    onClick={() => {
                                        logAmplitude(pageName, "Clicked Privacy Policy Link");
                                    }}
                                >
                          Privacy Policy
                        </span>
                                . I authorize Wisetack and its lending partners to obtain my credit report and
                                verify my information.
                            </div>
                        </div>
                    </div>
                </FormRow>
            </Form>
        }
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
        <PrequalInfoPageBottom
            display={!requestInProgress}
            btnDisabled={!reviewed || !formik.isValid}
            onButtonClick={formik.handleSubmit}
        />
        <TermsOfService />
        <PrivacyPolicyModal />
        <ElectronicDisclousersModal />
        <IncomeInfoModal />
        <ConsumerEntryPageMoreInfoModal/>
    </Container>
}

export default connect(null, {prequalPatch, prequalGet, submitCheckboxSelection, neuroIdSetSessionStarted})(
    PrequalEntryPage
);
