import React from "react";

import styles from "./Ssn4Form.module.scss";
import classNames from "classnames";
import ssnImage from "@wisetack/assets/img/ssn-icon-2@3x.png";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";

const SSN_PREFIX = "XXX-XX-";
const validator = new BorrowerFieldValidator("TIL Page");

export function Ssn4Form({ssn4, setSsn4, errors, setErrors}) {
    const handleOnChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;

        val = val.replace(/\D/g, "");

        if (val.length > 4) {
            return;
        }
        val = SSN_PREFIX + val;

        setSsn4(val)
        setErrors({[name]: ""})
    }

    const handleOnBlur = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        setErrors({[name]: validator.validateHiddenStartSSN(val)})
    }

    const handleOnFocus = (e) => {
        if (!ssn4) {
            setSsn4(SSN_PREFIX)
            setTimeout(function() {
                if (e.target.setSelectionRange) {
                    e.target.setSelectionRange(SSN_PREFIX.length, SSN_PREFIX.length);
                }
            }, 100);
        }
    }

    return (
        <div className={classNames("row", styles.ssn4form)}>
            <div className="col-1" style={{ minWidth: "40px" }}>
                <img className={styles.ssn} src={ssnImage} alt="bank-logo" />
            </div>
            <FormInput
                inputMode="numeric"
                pattern="[0-9]*"
                name="ssn4"
                label="Enter last 4-digits of SSN to sign"
                value={ssn4}
                onFocus={handleOnFocus}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                errors={errors}
            />
        </div>
    )
}
