import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./MerchantExpiredPage.module.scss";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

const pageName = "Expired Page (due to Pre-activated Merchant)"

class MerchantExpiredPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
    }

    render() {
        return (
            <Container>
                <PageHeader progress="10%">
                    <Fragment>
                        This application has expired
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    Unfortunately, {this.props.merchantName || "merchant"} didn't accept Wisetack financing for&nbsp;this&nbsp;job.
                </div>
                <div className={styles.content}>
                    Your loan application has expired and your credit score will not be impacted. Please work with the merchant to pay by&nbsp;another&nbsp;method.
                </div>
            </Container>
        );
    }
}

MerchantExpiredPage.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    status: state.consumer.status
});

export default connect(
    mapStateToProps,
    {}
)(MerchantExpiredPage);