import React from "react";

import image from "@wisetack/assets/img/contract.svg";
import styles from "./PayoutReview.module.scss";
import {formatAmount, formatAPR} from "@wisetack/shared-ui/utils/format";
import moment from "moment";

export const PayoutReview = ({payoutData}) => {

    if (!payoutData) {
        return null;
    }

    return <div className={styles.review}>
        <img className={styles.image} src={image} alt="bank-logo" />
        <div className={styles.name}>{payoutData.payoutName}</div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Financed Amount:</span>
            <span className={styles.value}>{payoutData.approvedAmount}</span>
        </div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Number of Payments:</span>
            <span className={styles.value}>{payoutData.numberOfPayments}</span>
        </div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Amount of Each Payment:</span>
            <span className={styles.value}>{formatAmount(payoutData.amount)}</span>
        </div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Due Monthly<br/><span style={{paddingLeft: "10px", fontStyle: "italic"}}>Estimated Starting:</span></span>
            <span className={styles.value}><i>{moment(payoutData.starting, "MM DD YYYY").format("M/D/YY")}</i></span>
        </div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Total Finance Charges:</span>
            <span className={styles.value}>{formatAmount(payoutData.interest)}</span>
        </div>
        <div style={{lineHeight:1.2}}>
            <span className={styles.label}>Sum of All Payments:</span>
            <span className={styles.value}>{formatAmount(payoutData.totalPayments)}</span>
        </div>
        <div className={styles.aap}>
            <span className={styles.annual}>ANNUAL PERCENTAGE RATE</span>
            <span className={styles.apr}>{formatAPR(payoutData.apr)}</span>
        </div>
        <div className={styles.label}>
            (The cost of your credit as a yearly rate)
        </div>
    </div>
}