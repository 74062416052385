import {
    PLAID_REQUEST_STARTED,
    PLAID_REQUEST_ENDED,
    PLAID_GET_ITEMS,
    PLAID_ERROR,
    PLAID_REGISTRATION,
    PLAID_DELETE_ITEM,
    PLAID_GET_ACCOUNT,
    PLAID_GET_BALANCE,
    PLAID_GET_PI,
    PLAID_GET_INCOME,
    PLAID_CLEAR_DATA
} from './types';

import Log from '@wisetack/shared-ui/utils/Log'

import axios from 'axios';

const apiPost = async (path, body, dispatch) => {
    const URL = `${window._wtenv_?.REACT_APP_API_URL}${path}`
    let data = null;
    try {
        dispatch({ type: PLAID_REQUEST_STARTED });
        const response = await axios.post(URL, body);
        Log.info(response, `API`);
        if (response.data.responseBody) {
            if (response.data.responseBody.errorMessage) {
                throw new Error(response.data.responseBody.errorMessage);
            } else {
                data = response.data;
            }
        } else {
            throw new Error('responseBody not found');
        }
    } catch (err) {
        if (axios.isCancel(err)) {
            // ignore
        } else {
            let errorMessage = err.message;
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                errorMessage = JSON.stringify(err.response, null, 2);
            }
            throw new Error(errorMessage);
        }
    } finally {
        dispatch({ type: PLAID_REQUEST_ENDED });
    }
    return data
}

const apiInvoke = async (path, body, dispatch, type) => {
    try {
        const payload = await apiPost(path, body, dispatch)
        dispatch({
            type,
            payload: payload.responseBody
        });
    } catch (err) {
        dispatch({
            type: PLAID_ERROR,
            payload: err.message
        });
    }
}

export const plaidRegistration = (customerId, plaidToken) => async dispatch => {
    apiInvoke("/plaid/registration", { customerId, plaidToken }, dispatch, PLAID_REGISTRATION);
}

export const getPlaidItems = (customerId) => async dispatch => {
    let requestBody = {}
    if (customerId) {
        requestBody.customerId = customerId;
    }
    apiInvoke("/plaid/registrationlist", requestBody, dispatch, PLAID_GET_ITEMS);
};

export const getPlaidAccount = (customerId, institutionId) => async dispatch => {
    apiInvoke("/plaid/account", { customerId, institutionId }, dispatch, PLAID_GET_ACCOUNT);
};

export const getPlaidBalance = (customerId, institutionId) => async dispatch => {
    apiInvoke("/plaid/balance", { customerId, institutionId }, dispatch, PLAID_GET_BALANCE);
};

export const getPlaidPI = (customerId, institutionId) => async dispatch => {
    apiInvoke("/plaid/pi", { customerId, institutionId }, dispatch, PLAID_GET_PI);
};

export const getPlaidIncome = (customerId, institutionId) => async dispatch => {
    apiInvoke("/plaid/income", { customerId, institutionId }, dispatch, PLAID_GET_INCOME);
};

export const deletePlaidItem = (customerId, institutionId) => async dispatch => {
    try {
        await apiPost("/plaid/registration", { customerId, institutionId, remove: true }, dispatch)
        dispatch({
            type: PLAID_DELETE_ITEM,
            payload: { customerId, institutionId }
        });
    } catch (err) {
        dispatch({
            type: PLAID_ERROR,
            payload: err.message
        });
    }
};

export const clearData = () => ({ type: PLAID_CLEAR_DATA })
