import moment from "moment";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import plane from "@wisetack/assets/img/paper-plane.svg";
import styles from "./ConsumerConfirmationPage.module.scss";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import {getVerticalFragment} from "../utils/verticalMessages";

class ConsumerConfirmationReminder extends React.Component {

  state = {}

  componentDidMount() {
    window.scrollTo(0, 0);
    logAmplitudeEvent(
        "Confirm Later Reminder page",
        {
          loanApplicationId: this.props.loanAppId,
          serviceCompletionProvidedByBorrower: this.props.serviceCompletionProvidedByBorrower
        }
    );
  }

  render() {
    const {
      doingBusinessAs,
      loanAppExpirationDate,
      merchantName,
      serviceCompletionProvidedByBorrower,
      isLoading
    } = this.props;

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
          <div>
            <Loader />
            <div className={styles.message}>Wait a moment please...</div>
          </div>
      );
    };

    return (
        <>
          {!isLoading ? (
            serviceCompletionProvidedByBorrower ?
              (
                  <Container>
                    <PageHeader progress="100%">
                      <Fragment>
                        Great! We’ll send you a reminder to&nbsp;come&nbsp;back
                      </Fragment>
                      <Fragment />
                    </PageHeader>
                    <div className={styles.content}>
                      <div style={{ textAlign: "center", paddingTop: "5px" }}>
                        <img className={styles.image} src={plane} alt="paper-plane" />
                      </div>
                      {getVerticalFragment('confirm_remainder_with_date_message', this.props.vertical, {
                        merchantName: doingBusinessAs ? doingBusinessAs : merchantName,
                        completionDate: moment(serviceCompletionProvidedByBorrower, "YYYY-MM-DD").format("LL")
                      })}
                    </div>
                  </Container>
              )
              :
              (
                  <Container>
                    <PageHeader progress="100%">
                      {getVerticalFragment('confirm_remainder_without_date_header', this.props.vertical)}
                      <Fragment />
                    </PageHeader>
                    <div className={styles.content}>
                      <div style={{ textAlign: "center", paddingTop: "5px" }}>
                        <img className={styles.image} src={plane} alt="paper-plane" />
                      </div>
                      {getVerticalFragment('confirm_remainder_without_date_message', this.props.vertical, {
                        merchantName: doingBusinessAs ? doingBusinessAs : merchantName
                      })}
                      <p>
                        Your offer is locked-in until <strong>{moment(loanAppExpirationDate, "MM DD YYYY").format("LL")}</strong>.
                      </p>
                    </div>
                  </Container>
              )
          ) : (
              <Container>
                <PageHeader progress="100%">
                  <Fragment />
                  <Fragment />
                </PageHeader>
              </Container>
          )}
          <LoaderWithMessage />
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  doingBusinessAs: state.consumer.doingBusinessAs,
  loanAppExpirationDate: state.consumer.loanAppExpirationDate,
  merchantName: state.consumer.merchantName,
  serviceCompletionProvidedByBorrower: state.consumer.serviceCompletionProvidedByBorrower,
  loanAppId: state.consumer.loanAppId,
  vertical: state.consumer.vertical,
  isLoading: state.consumer.isLoading
});

export default connect(mapStateToProps, {})(ConsumerConfirmationReminder);
