import {
    PLAID_REQUEST_STARTED,
    PLAID_REQUEST_ENDED,
    PLAID_GET_ITEMS,
    PLAID_ERROR,
    PLAID_CLEAR_DATA,
    PLAID_REGISTRATION,
    PLAID_DELETE_ITEM,
    PLAID_GET_ACCOUNT,
    PLAID_GET_BALANCE,
    PLAID_GET_INCOME,
    PLAID_GET_PI
} from '../actions/types'

const initState = {
    registrationList: [],
    registrationResult: null,
    personalInformation: null,
    accounts: null,
    balance: null,
    income: null,
    isLoading: false,
    errorMessage: null
}

const plaidReducer = (state = initState, action) => {
    switch (action.type) {
        case PLAID_REQUEST_STARTED:
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
                registrationResult: null,
                personalInformation: null,
                accounts: null,
                balance: null,
                income: null                
            };
        case PLAID_REQUEST_ENDED:
            return {
                ...state,
                isLoading: false
            };
        case PLAID_REGISTRATION:
            return {
                ...state,
                registrationResult: action.payload
            };
        case PLAID_GET_ITEMS:
            return {
                ...state,
                registrationList: action.payload.result
            };
        case PLAID_GET_PI:
            return {
                ...state,
                personalInformation: action.payload
            };
        case PLAID_GET_INCOME:
            return {
                ...state,
                income: action.payload
            };
        case PLAID_GET_ACCOUNT:
            return {
                ...state,
                accounts: action.payload
            };
        case PLAID_GET_BALANCE:
            return {
                ...state,
                balance: action.payload
            };
        case PLAID_DELETE_ITEM:
            return {
                ...state,
                registrationList: state.registrationList.filter(item => item.customerId !== action.payload.customerId ||
                    item.institutionId !== action.payload.institutionId)
            };
        case PLAID_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };
        case PLAID_CLEAR_DATA:
            return {
                ...initState
            };
        default:
            return state;
    }
};

export default plaidReducer;