import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import CardCollectionForm from "./CardCollectionForm";
import {LoaderWithMessage} from "../../components/LoaderWithMessage";
import {getLoanStatus} from "../../store/actions/consumerActions";
import {
    CARD_COLLECTED_MESSAGE,
    CLOSE_POPUPS_MESSAGE,
    PARENT_SESSION_DATA,
    POPUP_OPENED_MESSAGE,
    RE_ENABLE_ADD_DEBIT_CARD_BUTTON_MESSAGE
} from "./CardCollectionConstants";
import Log from "@wisetack/shared-ui/utils/Log";

class CardCollectionPopUp extends React.Component {

    constructor(props) {
        super(props);

        this.setUpWindowListener();
        window.opener.postMessage({ message: POPUP_OPENED_MESSAGE }, window.location.origin);
    }

    onUnload = () => {
        window.opener.postMessage({ message: RE_ENABLE_ADD_DEBIT_CARD_BUTTON_MESSAGE }, window.location.origin);
    };

    componentDidMount = () => {
        window.addEventListener("beforeunload", this.onUnload);
    }

    componentDidUpdate = (prevProps) => {
        if (this.state && this.props.status) {
            this.handleEntry(prevProps);
        }
    }

    setUpWindowListener = () => {
        window.addEventListener("message", (e) => {
            if (e.origin !== window.location.origin || !e.data.message) {
                return;
            }

            switch (e.data.message) {
                case CLOSE_POPUPS_MESSAGE:
                    window.close();
                    break;
                case PARENT_SESSION_DATA:
                    sessionStorage.setItem("wisetack:ba:token", e.data.sessionToken);
                    sessionStorage.setItem("wisetack:session", e.data.session);
                    const token = e.data.token;
                    localStorage.setItem("token", token);
                    this.props.getLoanStatus(token);

                    this.setState({...this.state, firstMonthPrepayment: true});
                    break;
                default:
                    break;
            }
        }, false);
    }

    handleEntry = (prevProps) => {

        const cardCollected = this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED" ||
            (prevProps.status === "CONDITIONAL_APPROVAL" && this.props.status === "OFFER_AVAILABLE");

        if (!this.state.firstMonthPrepayment) {
            this.props.history.push("/error");
        } else if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        } else if (cardCollected) {
            window.opener.postMessage({ message: CARD_COLLECTED_MESSAGE }, window.location.origin);
            window.close();
        } else if (this.props.status === "CONDITIONAL_APPROVAL") {
            //stay here!
        } else {
            this.props.history.push("/error");
        }

    }

    render = () => {
        let showContent = (this.state &&
                            this.state.firstMonthPrepayment &&
                            this.props.loanAppExpirationDate &&
                            this.props.loanAppId);

        return (
            <Container>
                <PageHeader progress="40%">
                    {showContent &&
                        <Fragment>
                            <div>
                                Add a debit card
                            </div>
                        </Fragment>
                    }
                    {showContent &&
                        <Fragment>
                            <div>
                                {/*PageHeader needs a 2nd child to show the title*/}
                            </div>
                        </Fragment>
                    }
                </PageHeader>
                <LoaderWithMessage isLoading={!showContent} duration={'short'}/>
                {showContent && <CardCollectionForm loanExpirationDate={this.props.loanAppExpirationDate}/>}
            </Container>
        );
    }
}

CardCollectionPopUp.propTypes = {
    getLoanStatus: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    loanAppId: state.consumer.loanAppId,
    status: state.consumer.status
});

export default connect(mapStateToProps, {getLoanStatus})(
    CardCollectionPopUp
);
