import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {useHistory} from "react-router";
import {getVerticalFragment} from "../utils/verticalMessages";
import styles from "./LoanPayoutConfirmPendingPage.module.scss";
import usePayoutFlow from "../hooks/usePayoutFlow";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormDatepickerFlatpickrInput from "@wisetack/shared-ui/components/FormDatepickerFlatpickrInput";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator"
import classNames from "classnames";
import {CONSUMER_SELECT_PAYOUT} from "../store/actions/types";
import {submitData} from "../store/actions/consumerActions";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import moment from "moment";
import usePayoutLog from "../hooks/usePayoutLog";

const pageName = "Loan Payout Confirmation Not Done Page"
const validator = new BorrowerFieldValidator(pageName);

function LoanPayoutConfirmPendingPage({submitData}) {
    const history = useHistory();
    const dispatch = useDispatch()

    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);
    const vertical = useSelector(state => state.consumer.vertical);
    const loanAppId = useSelector(state => state.consumer.loanAppId);
    const submitDataRequestId = useSelector(state => state.consumer.submitDataRequestId);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const payoutData = usePayoutFlow(selectedPlan, selectedPayout);

    const logAmplitude = usePayoutLog(pageName)

    const [serviceCompletionProvidedByBorrower, setServiceCompletionProvidedByBorrower] = useState("");
    const [error, setError] = useState("");
    const errorPresent = !!error

    useEffect(() => {
        if (!!requestId && requestId === submitDataRequestId) {
            dispatch({ type: CONSUMER_SELECT_PAYOUT, payload: {
                ...selectedPayout,
                path: "/loan_payout_confirm_reminder"
            } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, submitDataRequestId]);

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/loan_payouts")
    }

    const validateScheduledCompletionDate = (date) => {
        const errorType = validator.validateScheduledCompletionDate(date, payoutData.expDate);
        if (errorType === 'INVALID_PAST_SCHEDULED_COMPLETION_DATE') {
            return getVerticalFragment('completion_error_past', vertical);
        }
        if (errorType === 'INVALID_PAST_TO_OFFER_COMPLETION_DATE') {
            return getVerticalFragment('completion_error_expired', vertical);
        }
        return errorType;
    }

    const checkDateValid = (value) => {
        setError(validateScheduledCompletionDate(value));
    }

    const handleOnChange = (e) => {
        const value = e.target.value;
        setServiceCompletionProvidedByBorrower(value)
        checkDateValid(value)
    }

    const handleOnButtonClick = () => {
        if (errorPresent) {
            return
        }
        logAmplitude("Confirm Later Button clicked")
        if (serviceCompletionProvidedByBorrower) {
            submitData(loanAppId, {
                serviceCompletionProvidedByBorrower: moment(serviceCompletionProvidedByBorrower).format('YYYY-MM-DD'),
                payoutId: payoutData.payoutId
            }, newRequest());
        } else {
            dispatch({ type: CONSUMER_SELECT_PAYOUT, payload: {
                ...selectedPayout,
                path: "/loan_payout_confirm_reminder"
            } })
        }
    }

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": errorPresent,
        [styles.buttonDisabled]: errorPresent,
        [styles.buttonEnabled]: !errorPresent
    });

    return <Container>
        <PageHeader progress="90%" onBack={handleOnBack}>
            <div>Please come back later to confirm your payout</div>
            <div/>
        </PageHeader>
        <div className={styles.content}>
            <div className={styles.message}>
                Your loan won’t start until you confirm it’s time to release funds.
            </div>
            <Form>
                <FormRow>
                    <FormDatepickerFlatpickrInput
                        name="serviceCompletionProvidedByBorrower"
                        completedOnDate = {serviceCompletionProvidedByBorrower}
                        label="Scheduled payment date (optional)"
                        onChange={handleOnChange}
                        onFocus={() => {
                            checkDateValid(serviceCompletionProvidedByBorrower)
                        }}
                        onBlur={() => {
                            checkDateValid(serviceCompletionProvidedByBorrower)
                        }}
                        skipValidation={true}
                    />
                    <p style={{color: "#FE6E65"}}>
                        { error || requestError }
                    </p>
                </FormRow>
                { !requestInProgress &&
                    <button
                        data-test-id="continue"
                        className={btnClasses}
                        onClick={handleOnButtonClick}
                    >
                        CONFIRM LATER
                    </button>
                }
                <LoaderWithMessage loading={!!requestId && requestInProgress}/>
            </Form>
        </div>
    </Container>

}

export default connect(null, {submitData})(
    LoanPayoutConfirmPendingPage
);
