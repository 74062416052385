"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAutocompleteSamples = getAutocompleteSamples;
function getAutocompleteSamples(searchValue) {
  var suggestions = data.filter(function (s) {
    return s.street_line.toUpperCase().startsWith(searchValue.toUpperCase());
  });
  return {
    "suggestions": suggestions.length > 7 ? suggestions.slice(0, 7) : suggestions
  };
}
var data = [{
  "street_line": "529 Anza St",
  "secondary": "",
  "city": "Mountain View",
  "state": "CA",
  "zipcode": "94041",
  "entries": 0
}, {
  "street_line": "707 1/2 6th Ave",
  "secondary": "",
  "city": "Venice",
  "state": "CA",
  "zipcode": "90291",
  "entries": 0
}, {
  "street_line": "707 1/2 6th St",
  "secondary": "",
  "city": "Hermosa Beach",
  "state": "CA",
  "zipcode": "90254",
  "entries": 0
}, {
  "street_line": "707 1/2 Acacia Ave",
  "secondary": "",
  "city": "Corona Del Mar",
  "state": "CA",
  "zipcode": "92625",
  "entries": 0
}, {
  "street_line": "707 1/2 Amalia Ave",
  "secondary": "",
  "city": "Los Angeles",
  "state": "CA",
  "zipcode": "90022",
  "entries": 0
}];