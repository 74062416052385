import React from "react";
import {connect, useSelector} from "react-redux";

import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Container from "@wisetack/shared-ui/components/Container";
import Error from "@wisetack/shared-ui/components/Error";
import icon from "@wisetack/assets/img/icon-exclamation.svg"
import styles from "./LimitExceededPage.module.scss";
import useDashboardFlow from "../hooks/useDashboardFlow";
import {postIframeEvent} from "@wisetack/shared-ui/utils/iframeEventProducer";

const pageName = "Limit Exceeded Page"

function LimitExceededPage() {
    const errorMessage = useSelector(state => state.consumer.errorMessage);

    useDashboardFlow(pageName);

    postIframeEvent({event: 'LIMIT_EXCEEDED'});

    return <Container>
        <PageHeader progress="20%">
            <div>Wisetack credit limit</div>
            <img className={styles.image} src={icon} alt={"icon-exclamation.svg"} />
        </PageHeader>
        <div className={styles.message}>
            Due to other pending loan applications, starting a new loan may exceed your total Wisetack credit limit.
            Please contact <b><a href="mailto:support@wisetack.com">support@wisetack.com</a></b> for assistance.
        </div>
        <Error pageName={pageName}>{errorMessage}</Error>
    </Container>
}

export default connect(null, {})(
    LimitExceededPage
);
