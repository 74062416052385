import moment from "moment";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Log from "@wisetack/shared-ui/utils/Log";
import {formatAmount, formatAPR} from "@wisetack/shared-ui/utils/format";
import Error from "@wisetack/shared-ui/components/Error";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import classNames from "classnames";
import styles from "./ConsumerReviewPlanPage.module.scss";
import image from "@wisetack/assets/img/contract.svg";
import {
    acceptTruthInLending,
    getOffers,
    getSettings,
    getTila,
    sendDocumentCopy,
    setError,
    submitCheckboxSelection
} from "../store/actions/consumerActions";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";
import LoanAgreementModal from "@wisetack/shared-ui/components/LoanAgreementModal";
import CreditScoreModal from "@wisetack/shared-ui/components/CreditScoreModal";
import ssnImage from "@wisetack/assets/img/ssn-icon-2@3x.png";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import {CitizensPayoutReview} from "../components/CitizensPayoutReview";
import {CitizensSummaryTable} from "../components/CitizensSummaryTable";
import CitizensLockInYourOfferModal from "@wisetack/shared-ui/components/CitizensLockInYourOfferModal";
import {PartnerPrequalConsent} from "../components/PartnerPrequalConsent";
import {getAccessToken} from "../utils/storage";

const pageName = "TIL Page";

class ConsumerReviewPlanPage extends React.Component {

  state = {
    reviewed: this.props.confirmed,
    ssn: "",
    displaySSN: "",
    errors: {},
    isCitizensAllocated: false
  };

  constructor(props) {
    super(props);
    this.validator = new BorrowerFieldValidator(pageName)
  }

  isPayoutsListFound() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  isReturningCustomer() {
      return this.props.selectedPlan && this.props.selectedPlan.partnerOperation === 'RETURNING_CUSTOMER'
  }

  navigateToPayouts() {
    if (this.isPayoutsListFound()) {
      this.props.history.push("/loan_payouts");
      return true;
    }
    return false;
  }

  isSelectedPlanFound(props) {
    if (!props) {
        props = this.props
    }
    if (!props.selectedLoanOfferId) {
      return false
    }
    if (!props.selectedPlan) {
      return false
    }
    return Object.keys(props.selectedPlan).length > 0
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.loanAppId) {
      this.props.history.push("/error");
      this.props.setError('Please use your payment link to log in.')
      return
    }
    if (this.navigateToPayouts()) {
      return
    }
    if (!this.isSelectedPlanFound()) {
      // selectedLoanOfferId can be unknown here as request to get loan application data can still be running
      // so instead to wait for selectedLoanOfferId we will get all loan offers in parallel
      this.props.getOffers(this.props.loanAppId)
    }
    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName,
      emailVerificationRequired: !!this.props.emailVerificationRequired
    }
    logAmplitudeEvent(pageName, this.logProps);
    this.validator.props = this.logProps;
    this.setState({ isCitizensAllocated: (this.isSelectedPlanFound() && this.props.selectedPlan.partner === 'CITIZENS')});
    this.props.getSettings(this.props.loanAppId, getAccessToken(this.props.loanAppId));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.anotherOfferRequired) {
      this.props.history.push("/offers_updated");
      return;
    }
    if (this.props.partnerPending) {
      this.props.history.push("/pending");
      return;
    }
    if (this.navigateToPayouts()) {
      return
    }
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
      return;
    }
    if (this.props.declineReason) {
      Log.info(this.props.declineReason, `declineReason`);
      if (this.props.declineReason === 'INELIGIBLE_FOR_ADDITIONAL_LOAN') {
        this.props.history.push("/declined_max");
      } else {
        this.props.history.push("/declined");
      }
      return;
    }
    if (this.props.firstMonthPrepayment && !this.props.cardLastFourDigits && this.isHatchConfirmed()) {
        // SQC-662 redirect back to choose plan page if Hatch allocation confirmed but card data not collected
        // (can happen on card collection page reload)
        this.props.history.push("/choose_plan");
        return;
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
      return;
    }
    if (!this.isSelectedPlanFound(prevProps) && this.isSelectedPlanFound()) {
        this.setState({ isCitizensAllocated: this.props.selectedPlan.partner === 'CITIZENS'})
    }
    if (this.props.ssn4Error) {
      Log.info(this.props.ssn4Error, `ssn4error...`);
      if (prevProps.ssn4Error !== this.props.ssn4Error)
        this.setState({
          errors: {
            ...this.state.errors,
            ssn4: this.props.ssn4Error
          }
        });
    }
    // add logic here to route to another page depending from status
    switch (this.props.selectedLoanOfferStatus) {
      case "TILA_ACCEPTED":
      case "CONVERTED":
        if (this.props.emailVerificationRequired === true) {
          this.props.history.push("/email_confirm");
          break;
        }
        if (this.props.serviceCompletionDate && moment(this.props.serviceCompletionDate).isAfter(moment(), 'day')) {
          this.props.history.push("/purchase_confirm_pending");
        } else {
          this.props.history.push("/purchase_confirm");
        }
        break;
      case "NOT_SELECTED":
        this.props.history.push("/choose_plan");
        break;
      default:
        break;
    }
  }

  isHatchConfirmed() {
    return this.props.partnerAllocationMap && this.props.partnerAllocationMap.confirmed === 'HATCH'
  }

  handleOnButtonClick = () => {
    const plan = this.props.selectedPlan || {};
    logAmplitudeEvent("Pressed Accept Terms and Sign Button", {
      ...this.logProps,
      offerSelectedDuration: plan.months,
      selectedInterestFreeOffer: plan.apr === 0,
      emailVerificationRequired: !!this.props.emailVerificationRequired
    });
    this.props.acceptTruthInLending(
      this.props.loanAppId,
      this.props.selectedLoanOfferId,
      this.state.ssn,
      this.state.reviewed
    );
  };

  handleOnEnterPress = event => {
    event.preventDefault();
    if (this.state.reviewed && event.key === 'Enter') {
      this.handleOnButtonClick();
    }
  }

  validateField = (name, val) => {
    switch (name) {
      case "ssn4":
        if (this.state.isCitizensAllocated && !this.isReturningCustomer()) {
            return this.validator.validateSSN(this.state.ssn);
        } else {
            return this.validator.validateHiddenStartSSN(val.slice(-4));
        }
      default:
        return "";
    }
  };

  handleOnBlur = e => {
    let val = e.target.value;
    let name = e.target.name;
    const error = this.validateField(name, val);
    this.setError(name, error);
  };

    generateDisplaySSN = (ssn, isAddingCharacters) => {
        let newDisplaySSN = "";

        for (let i = 0; i < ssn.length; i++) {
            if (i === 3 || i === 5) {
                newDisplaySSN += '-';
            }
            newDisplaySSN += ((i <= ssn.length - 2 || !isAddingCharacters) && i <= 4) ? "X" : ssn.charAt(i);
        }
        return newDisplaySSN
    }

    handleSSNChange = e => {
        let inputValue = '';
        // Test input for a string value to prevent 'e.target.value.replaceAll is not a function' error
        if (e && e.target && e.target.value && typeof e.target.value === 'string'){
            inputValue = e.target.value;
        } else {
            // if there was an error reset values to starting strings
            const resetDisplayValue = this.state.isCitizensAllocated ? '' : 'XXX-XX-';
            this.setState({displaySSN: resetDisplayValue, ssn: ''});
            return;
        }

        let val = inputValue.replaceAll('-', '');

        if (this.state.isCitizensAllocated && !this.isReturningCustomer()) {
            if (val.length > 9){
                return;
            }

            const isAddingCharacters = val.length > this.state.ssn.length;

            let newSSN = "";
            if (isAddingCharacters) {
                const start = this.state.ssn.length;
                const toAdd = val.slice(start, val.length);
                newSSN = this.state.ssn + toAdd;
            } else {
                newSSN = this.state.ssn.slice(0, val.length);
            }

            const newDisplaySSN = this.generateDisplaySSN(newSSN, isAddingCharacters);

            this.setState({displaySSN: newDisplaySSN, ssn: newSSN});
        } else {
            val = e.target.value.replace(/\D/g, "");

            if (val.length > 4) {
                return;
            }

            val = "XXX-XX-" + val;

            this.setState({displaySSN: val, ssn: val});
        }

        let name = e && e.target && e.target.name ? e.target.name : '';
        this.setError(name, "");
    };

  setError(name, error) {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: error
      }
    });
  };

  canGoToOffers = () => {
    let res = this.props.status === "CONDITIONAL_APPROVAL"
        || this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED"
        || this.props.status === "OFFER_AVAILABLE";
    //res= false;
    return res;
  }

  handleOnBackToOfferClick = () => {
    logAmplitudeEvent("Pressed Back to offers Button", this.logProps);
    if(this.canGoToOffers()) {
      this.props.history.push("/choose_plan");
      return;
    }
    return;
  };



  render() {
    Log.info(this.props, `ConsumerReviewPlanPage props`);

    const plan = this.props.selectedPlan || {};

    let btnDisabled =
        (!this.state.reviewed && !this.isReturningCustomer())
            || (!this.props.checkboxes['ALMOST_DONE_PAGE'] && !this.isReturningCustomer())
            || Object.keys(plan).length === 0
            || !this.state.ssn || (this.state.isCitizensAllocated && !this.isReturningCustomer() && this.state.ssn.length !== 9)
            || (!this.state.isCitizensAllocated && this.state.ssn.length !== 11);

    for (const key in this.state.errors) {
      if (this.state.errors[key]) btnDisabled = true;
    }

    const btnClasses = classNames({
      btn: true,
      "btn-block": true,
      "btn-disabled": btnDisabled,
      [styles.buttonDisabled]: btnDisabled,
      [styles.buttonEnabled]: !btnDisabled
    });

    const showContent = !this.props.errorMessage && !this.props.isLoading;

    const loadTila = () => {
      return new Promise((resolve, reject) => {
          if (!this.props.loanAppId || !this.props.selectedLoanOfferId) {
            reject()
            return;
          }
          getTila(this.props.loanAppId, this.props.selectedLoanOfferId)
          .then( res => {
            if (res && res.loanOfferDetailsList && res.loanOfferDetailsList[0] && res.loanOfferDetailsList[0].truthInLending) {
              resolve(atob(res.loanOfferDetailsList[0].truthInLending));
            }
          })
          .catch(err => reject(err));
      });
    }

    const btnBackClasses = classNames({
      [styles.buttonBack]: true
    });


    const backToOffersButton = () => {
      if(!this.canGoToOffers()){
        return null;
      }

      return <div className="col">
          <div
              data-test-id="back to offers"
              className={btnBackClasses}
              onClick={this.handleOnBackToOfferClick}
          >
            BACK TO OFFERS
          </div>
        </div>
    }

    const ConditionalHeader = () => {
        const amount = this.props.selectedPlan ? formatAmount(this.props.selectedPlan.approvedAmount) : ""
        return <PageHeader progress="71%" onBack={this.canGoToOffers() ? () => this.handleOnBackToOfferClick() : () => {}}>
          {showContent ? <Fragment>{this.state.isCitizensAllocated ? 'Lock in your offer for your purchase of ' + amount : 'Lock in your offer'}</Fragment>: null}
          {showContent && this.state.isCitizensAllocated && !this.isReturningCustomer() ? (
              <div style={{textAlign: "center"}}>
                   Continue to lock in your offer for 90 days
                   <span data-toggle="modal" data-target="#citizensLockInYourOffer"
                        onClick={() => {
                            logAmplitudeEvent("Clicked more Info of Consumer Review Plan Page Link", this.logProps);
                        }}
                        className={classNames("material-icons", styles.info_icon)}>info_outline</span>
               </div>) : (<div></div>)
           }
           {null}
        </PageHeader>
    }

    const LoanSummaryTable = () => {
    return (
        <div className={styles.truth}>
          <img className={styles.image} src={image} alt="bank-logo" />
              <div className={styles.title}>FINANCING SUMMARY</div>
          <div className={styles.items}>
            <div className={styles.item}>
              Financed Amount:{" "}
              <span>{formatAmount(plan.approvedAmount)}</span>
            </div>
            <div className={styles.item}>
              Number of Payments: <span>{plan.numberOfPayments}</span>
            </div>
            <div className={styles.item}>
              Amount of Each Payment:{" "}
              <span>{formatAmount(plan.amount)}</span>
            </div>
            <div className={styles.item}>
                  Due Monthly{firstMonthPrepayment ? " After Loan Confirmation" : ""}
            </div>
            <div className={styles.item}>
              &nbsp;&nbsp;&nbsp;&nbsp;<i>Estimated Starting:{" "}</i>
              <span><i>{plan.starting}</i></span>
            </div>
            <div className={styles.item}>
              Total Finance Charges:{" "}
              <span>{formatAmount(plan.interest)}</span>
            </div>
            <div className={styles.item}>
              Sum of All Payments:{" "}
              <span>{formatAmount(plan.totalPayments)}</span>
            </div>
          </div>
          <div className={styles.line} />
          <div className={classNames("row", styles.title)}>
            <div className={styles.annualPercentageText}>
              Annual Percentage Rate
            </div>
            <div
              className={styles.annualPercentageValue}
              style={{ textAlign: "right" }}
            >
              {formatAPR(plan.apr)}
            </div>
          </div>
          <div className={styles.annualPercentageTextLabel}>
            (The cost of your credit as a yearly rate)
          </div>
        </div>
        )
    }

    const FlowSummary = () => {
        if (this.state.isCitizensAllocated) {
            const selectedPlan = this.props.selectedPlan || {}
            return (<CitizensPayoutReview
                        numberOfPayments={selectedPlan.numberOfPayments ? selectedPlan.numberOfPayments : 0 }
                        amountOfPayments={selectedPlan.amount ? selectedPlan.amount : 0 }
                        apr={selectedPlan.apr} />)
        } else {
            return (<LoanSummaryTable />)
        }
    }

    const Consent = () => {
        if (this.state.isCitizensAllocated && this.isReturningCustomer()) {
            return (
                <div className={classNames("col", styles.reviewed)} style={{lineHeight: 1.5, paddingRight: "1px", fontSize: "12px"}}>
                    This purchase uses your existing <b>Citizens Pay line of credit</b>, and you’ll see it reflected in your Citizens Pay account. This won’t result in another credit inquiry.
                </div>
            )
        } else if (this.state.isCitizensAllocated) {
            return (
                <div className={classNames("col", styles.reviewed)}>
                    <PartnerPrequalConsent/>
                </div>
            )
        } else {
            return (
                <div className={classNames("col", styles.reviewed)} style={{lineHeight: 1.5}}>
                    I have reviewed and agree to the
                    <span data-toggle="modal" data-target="#truthInLendingModal"
                        onClick={() => {
                            logAmplitudeEvent("Opened TIL link", this.logProps);
                        }}>&nbsp;Truth in Lending Disclosure
                    </span>
                    &nbsp;and{" "}
                    <span data-toggle="modal" data-target="#loanAgreement"
                        onClick={() => {
                            logAmplitudeEvent("Opened Loan agreement link", this.logProps);
                        }}>&nbsp;Loan Agreement</span>
                        , and have received the
                    <span data-toggle="modal" data-target="#creditScoreModal"
                        onClick={() => {
                            logAmplitudeEvent("Opened Credit Score disclosure link", this.logProps);
                        }}>&nbsp;Credit Score Disclosure.</span>
                </div>
            )
        }
    }

    const CitizensTable = () => {
        if (this.state.isCitizensAllocated && !this.isReturningCustomer())
        {
            const settingsData = this.props.settingsData || {}
            return (
                <div className={styles.table_viewer}>
                    <CitizensSummaryTable
                        schumerBoxAPR={settingsData.schumerBoxAPR}
                        schumerBoxAnnualFee={settingsData.schumerBoxAnnualFee}
                        schumerBoxPenaltyFee={settingsData.schumerBoxPenaltyFee} />
                </div>);
        } else {
            return <div></div>;
        }
    }

    const SubmitButton = () => {
    return (
        <div className={this.state.isCitizensAllocated && !this.isReturningCustomer() ? styles.fixed : styles.flex}>
            <button data-test-id="confirm" className={btnClasses}
                onClick={this.handleOnButtonClick}
                onKeyPress={this.handleOnEnterPress}>
                <span className="material-icons"
                    style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        paddingBottom: "3px"
                    }}
                >lock_outline
                </span>
                {this.state.isCitizensAllocated && this.isReturningCustomer() ?
                    "CONTINUE" : this.state.isCitizensAllocated ? "SIGN & SUBMIT" :"ACCEPT OFFER & SIGN"}
            </button>
            { false && backToOffersButton() }
            <div className={styles.message}>
                <span>Release funds in the next step</span>
                <span className={classNames("material-icons", styles.next_icon)}>arrow_forward</span>
            </div>
        </div>)
    }

    const firstMonthPrepayment = this.props.firstMonthPrepayment;

    return (
       <Container>
         { this.isSelectedPlanFound() && !this.isPayoutsListFound() &&
           <PdfModalZoom name="truthInLendingModal" dataLoadFunc={loadTila}
                allowCopySend={true} applicationID={this.props.loanAppId} documentID={this.props.truthInLendingID}
                prefill={this.props.email} sendFunction={sendDocumentCopy}
                headerTitle="Truth In Lending" />
         }
        <LoanAgreementModal />
        <CreditScoreModal />
        {showContent ? <ConditionalHeader /> : <PageHeader progress="71%"/>}
        <LoaderWithMessage isLoading={this.props.isLoading} />
        <Error pageName={pageName}>{this.props.errorMessage}</Error>
        {showContent ? (
            <>
            <FlowSummary />
            <div className={styles.approve}>
              <div className="row">
                  {!this.isReturningCustomer() &&
                      <div className="col-1" style={{minWidth: "40px"}}>
                      <div
                          data-test-id="reviewed"
                          role="dialog"
                          className={styles.box}
                          onClick={() => {
                              const rev = !this.state.reviewed;
                              this.props.submitCheckboxSelection(this.props.loanAppId, "ALMOST_DONE_PAGE", rev, {loanOfferId: this.props.selectedLoanOfferId});
                              this.setState({reviewed: rev});
                          }}
                      >
                          {this.state.reviewed && (
                              <span
                                  className="material-icons"
                                  style={{
                                      fontSize: "25px",
                                      paddingLeft: "1px",
                                      paddingTop: "1px"
                                  }}
                              >
                        done
                      </span>
                          )}
                      </div>
                  </div>}
                <Consent/>
              </div>
            </div>
            <div style={{ margin: "15px 0px 15px 15px", paddingRight: "25px" }}>
              <Form>
                <FormRow>
                  <div className="input-group-prepend">
                    <img className={styles.ssn} src={ssnImage} alt="bank-logo" />
                  </div>
                  <FormInput
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="ssn4"
                    label={this.state.isCitizensAllocated && !this.isReturningCustomer() ? "Enter SSN to sign" : "Enter Last 4 Digits of SSN to Sign" }
                    value={this.state.displaySSN}
                    onFocus={(e) => {
                        if (this.state.ssn === "" && (!this.state.isCitizensAllocated || this.isReturningCustomer())) {
                            this.setState({ displaySSN: "XXX-XX-" });
                        }
                    }}
                    onChange={this.handleSSNChange}
                    onBlur={this.handleOnBlur}
                    errors={this.state.errors}
                  />
                </FormRow>
              </Form>
            </div>
            <CitizensTable/>
            {SubmitButton()}
            </>
            ) : ( <div style={{ paddingBottom: "15px" }} /> )
        }
      <CitizensLockInYourOfferModal/>
      </Container>
    );
  }
}

ConsumerReviewPlanPage.propTypes = {
  confirmed: PropTypes.bool,
  selectedPlan: PropTypes.object,
  acceptTruthInLending: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  selectedLoanOfferId: state.consumer.selectedLoanOfferId,
  truthInLending: state.consumer.truthInLending,
  truthInLendingID: state.consumer.truthInLendingID,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  ssn4Error: state.consumer.ssn4Error,
  status: state.consumer.status,
  selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
  selectedPlan: state.consumer.selectedPlan,
  acceptedTruthInLendingId: state.consumer.acceptedTruthInLendingId,
  truthInLendingAcceptedAt: state.consumer.truthInLendingAcceptedAt,
  initExpired: state.consumer.initExpired,
  email: state.consumer.email,
  emailVerificationRequired: state.consumer.emailVerificationRequired,
  serviceCompletionDate: state.consumer.serviceCompletionDate,
  declineReason: state.consumer.declineReason,
  checkboxes: state.consumer.checkboxes,
  vertical: state.consumer.vertical,
  payouts: state.consumer.payouts,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment,
  cardLastFourDigits: state.consumer.cardLastFourDigits,
  partnerAllocationMap: state.consumer.partnerAllocationMap,
  anotherOfferRequired: state.consumer.anotherOfferRequired,
  partnerPending: state.consumer.partnerPending,
  settingsData: state.consumer.settingsData
});

export default connect(
  mapStateToProps,
  { getOffers, getAccessToken, getSettings, acceptTruthInLending, submitCheckboxSelection, setError}
)(ConsumerReviewPlanPage);
