let activity = 'activity: ';

export const trackPageActivity = (type, e) => {
    if (type === 'addressOnKeyUp') {
        onCharacterTyped(e, 'a');
        return;
    }
    if (type === 'addressSecondaryNumberOnKeyPress') {
        onCharacterTyped(e, 's');
        return;
    }
    if (type === 'cityOnKeyPress') {
        onCharacterTyped(e, 'c');
        return;
    }
    if (type === 'zipOnKeyPress') {
        onCharacterTyped(e, 'z');
        return;
    }
    if (type === 'autocompleteAddressSelected') {
        activity = activity + " -> autocomplete address selected";
        return;
    }
    if (type === 'invalidAddress') {
        activity = activity + " -> invalid address";
        return;
    }
    if (type === 'invalidZip') {
        activity = activity + " -> invalid zip";
    }
};

export const trackAddressPageSubmission = (data) => {
    activity = activity + "\n\n - summary:\n"
    activity = activity + summary("streetAddress1", data.streetAddress1, !(/^[\d\W]+$/).test(data.streetAddress1));
}

export const getPageActivity = () => {
    return activity;
};

function textLength(value) {
    return !!value ? value.length : 0;
}

function onCharacterTyped(e, field) {
    let length = textLength(e.target.value);
    if (e.key === "Backspace") {
        activity = activity + " -> -" + field + " (" + length + ")";
    } else {
        activity = activity + " -> +" + field + " (" + length + (e.key.match(/[a-z]/i) ? ", c)" : ", non-c)");
    }
}

function summary(field, value, valid) {
    return field + " with length - " + textLength(value)
        + " has " + (valid ? "valid" : "invalid") + " value";
}