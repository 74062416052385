import React from 'react';
import PropTypes from "prop-types";

const StandardLearnMoreContent = ({loanServicer}) => {
    return (
        <>
            {loanServicer === 'Peach' ?
                <p>
                    Look out for an email from us. There you can log in with your email or
                    mobile phone number to easily manage and pay back your Wisetack purchase. You will be able to:
                </p> :
                <p>
                    Look out for an email from <b>Scratch Servicing</b>, our loan servicer. There you can log in with your email or
                    mobile phone number to easily manage and pay back your Wisetack purchase. You will be able to:
                </p>
            }
            <ul>
                <li>Schedule and make payments</li>
                <li>Get statements</li>
                <li>View financing details</li>
                <li>Edit your information</li>
            </ul>
        </>
    );
};

StandardLearnMoreContent.propTypes = {
    loanServicer: PropTypes.string.isRequired
};

export default StandardLearnMoreContent;