import styles from "./ConsumerMoreInfoRequiredPage.module.scss";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Log from "@wisetack/shared-ui/utils/Log";
import {getPersonaInquiries} from "../store/actions/consumerActions";
import image from "@wisetack/assets/img/identity.svg";
import InquiryLauncher from "./InquiryLauncher";
import VerifyingYourIdentityModal from "./VerifyingYourIdentityModal";
import classNames from "classnames";
import infoImage from "@wisetack/assets/img/info-icon.svg";
import warnImage from "@wisetack/assets/img/warning-icon.svg";

const pageName = "Consumer More Info Required Page";

class ConsumerMoreInfoRequiredPage extends React.Component {

  state = {
    isInquiryLoading: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    logAmplitudeEvent(pageName, {
      loanId: this.props.loanID.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName,
    });
    if (this.props.loanID) {
      this.props.getPersonaInquiries(this.props.loanID);
    }
  }

  render() {
    Log.info(this.props, `ConsumerMoreInfoRequiredPage props`);
    const LoaderWithMessage = () => {
      if (!this.props.isLoading && !this.state.isInquiryLoading) return null;
      return (
        <div>
          <Loader />
          <div className={styles.message}>Wait a moment please...</div>
        </div>
      );
    };

    const onInquiryFinishBefore = () => {
      this.setState({isInquiryLoading: true});
    }

    const onInquiryFinish = (meta) => {
      this.setState({isInquiryLoading: false});
      this.props.getPersonaInquiries(this.props.loanID);
    }

    const verifyNowBtnClasses = classNames({
      btn: true,
      "btn-block": true,
      [styles.buttonEnabled]: true
    });

    const submittedBtnClasses = classNames({
      btn: true,
      "btn-block": true,
      "btn-disabled": true,
      [styles.buttonDisabled]: true
    });

    let fullName = this.props.firstName + ' ' + this.props.lastName;

    return (
      <Container>
        <VerifyingYourIdentityModal loanExpirationTime={this.props.loanAppExpirationDate}/>
        <PageHeader progress="57%">
          <Fragment>
            Verify your identity
            <br />
          </Fragment>
          <Fragment />
        </PageHeader>
        <LoaderWithMessage />
        {!this.props.isLoading && !this.state.isInquiryLoading ? (
          <div>
            <img className={styles.image} src={image} alt="calc-logo" />
            <div className={styles.content}>
              <p>
                  To continue with your application we’ll need to verify the driver’s license of
                <div>
                  <p><strong>{fullName}</strong></p>
                </div>
                <span>
                  &nbsp; &nbsp; &nbsp;Continue to securely verify.{" "}
                  <div
                      data-toggle="modal"
                      data-target="#verifyingYourIdentityModal"
                      onClick={() => {
                        logAmplitudeEvent("Opened Verifying Your Identity Modal", this.logProps);
                      }}
                  >
                    &nbsp; <b>Learn more</b>
                  </div>
                </span>
              </p>
              {
                this.props.isAnyInquiryFailed ? (
                    <>
                      <button disabled={true} className={submittedBtnClasses}
                              data-test-id="submitted-button">SUBMITTED
                      </button>
                      <div className={styles.couldNotVerifyWarnSquare}>
                        <div className={styles.couldNotVerifyWarnIcon}><img src={warnImage} alt="info-img"/></div>
                        <div className={styles.couldNotVerifyWarnText}>
                          We couldn’t verify your identity. For further assistance, contact <b>underwriting@wisetack.com</b>.
                        </div>
                      </div>
                    </>
                ) :(
                  <>
                    {
                      this.props.isAnyInquirySubmitted ? (
                          <>
                            <button disabled={true} className={submittedBtnClasses}
                                    data-test-id="submitted-button">SUBMITTED
                            </button>
                            <div className={styles.verifyAgainInfoSquare}>
                              <div className={styles.verifyAgainInfoIcon}><img src={infoImage} alt="info-img"/></div>
                              <div className={styles.verifyAgainInfoText}>
                                The verification process can take up to one business day, and you will receive a notification
                                when it’s done.
                              </div>
                            </div>
                          </>
                      ) : (
                          <>
                            <InquiryLauncher
                                templateId={this.props.inquiryTemplateId}
                                activeInquiry={this.props.activeInquiry}
                                piiData={this.props.piiData}
                                loanApplicationId={this.props.loanID}
                                onFinish={onInquiryFinish}
                                onInquiryFinishBefore={onInquiryFinishBefore}
                                onLoad={e => this.setState({isInquiryLoading: false})}
                            >
                              <button
                                  onClick={e => this.setState({isInquiryLoading: true})}
                                  className={verifyNowBtnClasses}
                                  data-test-id="verify-now-button">VERIFY NOW
                              </button>
                            </InquiryLauncher>
                          </>
                      )
                    }
                </>
                )
              }
            </div>
          </div>
        ) : (
          <div style={{ paddingBottom: "15px" }} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loanID: state.consumer.loanAppId,
  loanAppExpirationDate: state.consumer.loanAppExpirationDate,
  merchantName: state.consumer.merchantName,
  firstName: state.consumer.firstName,
  lastName: state.consumer.lastName,
  isLoading: state.consumer.isLoading,
  inquiryTemplateId: state.consumer.templateId,
  activeInquiry: state.consumer.activeInquiry,
  piiData: state.consumer.piiData,
  isAnyInquirySubmitted: !!state.consumer.anySubmitted,
  isAnyInquiryFailed: !!state.consumer.anyFailed
});

export default connect(
    mapStateToProps,
    { getPersonaInquiries }
)(ConsumerMoreInfoRequiredPage);
