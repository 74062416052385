"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailSuggester = void 0;
var _mailcheck = _interopRequireDefault(require("mailcheck"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var emailSuggester = function emailSuggester(values, callback) {
  _mailcheck["default"].run({
    email: values[0],
    suggested: function suggested(suggestion) {
      callback("Did you mean ".concat(suggestion['full'], "?"));
    },
    empty: callback
  });
};
exports.emailSuggester = emailSuggester;