import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
}

const store = createStore(rootReducer,
    compose(
        applyMiddleware(...middleware)
    )
);

export default store;