import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useLocation} from 'react-router-dom';
import {connect, useDispatch, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {formatAmount} from "@wisetack/shared-ui/utils/format";

import styles from "./LoanPayoutsPage.module.scss";
import {LoanPayout} from "./LoanPayout";
import {CONSUMER_SELECT_PAYOUT} from "../store/actions/types";
import usePayoutLog from "../hooks/usePayoutLog";
import {getOffers} from "../store/actions/consumerActions";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import PayoutsLearnMoreModal from "./PayoutsLearnMoreModal";


function LoanPayoutsPage({getOffers}) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    const payouts = useSelector(state => state.consumer.payouts);
    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const transactionAmount = useSelector(state => state.consumer.transactionAmount);
    const loanAppId = useSelector(state => state.consumer.loanAppId);
    const isLoading = useSelector(state => state.consumer.isLoading);
    const merchantName = useSelector(state => state.consumer.merchantName);

    const firstPayoutAmount = !!payouts && payouts.length > 0 ? payouts[0].approvedLoanAmount : 0
    const months = !!selectedPlan && selectedPlan.months
    const apr = !!selectedPlan && selectedPlan.apr

    const [backEnabled, setBackEnabled] = useState(true);

    const logAmplitude = usePayoutLog("Loan Payouts Page")

    useEffect(() => {
        if (loanAppId) {
            getOffers(loanAppId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!payouts && !!location && location.pathname === '/loan_payouts_return') {
            for (const payout of payouts) {
                console.log('location', payout)
                if (['/loan_payout_confirm', '/loan_payout_accept'].includes(payout.path)) {
                    goToPayout(payout)
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, payouts])

    useEffect(() => {
        if (!payouts || !transactionAmount) {
            history.push("/choose_plan")
            return;
        }
        for (const payout of payouts) {
            if (payout.status !== "OFFER_AVAILABLE" && payout.status !== "COUNTER_OFFER_AVAILABLE") {
                setBackEnabled(false)
            }
        }
        logAmplitude("Payouts loaded", {payouts, transactionAmount})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payouts, transactionAmount]);

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/choose_plan")
    }

    const handleOnPayoutClick = (payout) => {
        if (payout.disabled) {
            return
        }
        logAmplitude("Payout clicked", {payout})
        goToPayout(payout)
    }

    const goToPayout = (payout) => {
        dispatch({type: CONSUMER_SELECT_PAYOUT, payload: payout})
        if (payout.path) {
            history.push(payout.path)
        }
    }

    return <Container>
        <PageHeader progress="80%" onBack={backEnabled ? handleOnBack : null}>
            <div>Release payouts</div>
            <div/>
        </PageHeader>
        <LoaderWithMessage isLoading={isLoading}/>
        {!isLoading && <>
            <div className={styles.message}>
                <b>{merchantName}</b> requires <b>{formatAmount(firstPayoutAmount)}</b> of the service amount upfront,
                with the balance due at completion of service.
            </div>
            <div className={styles.message}>
                Select each payout to review terms and release payment.
                <span className={styles.learnMore}
                      data-toggle="modal"
                      data-target="#learnMorePayoutsModal"
                      onClick={() => {
                          logAmplitude("Opened Learn more link");
                      }}
                >
                    &nbsp;<b>Learn more.</b>
                    </span>
            </div>
            <div className={styles.plan}>
                Terms selected: {months} month plan, {apr}% APR
            </div>
            <div className={styles.total}>
                For your {formatAmount(transactionAmount)} service:
            </div>
            {
                payouts.map((item) => (
                    <LoanPayout
                        key={item.id}
                        item={item}
                        onClick={() => handleOnPayoutClick(item)}
                    />
                ))
            }
        </>
        }
        <PayoutsLearnMoreModal/>
    </Container>
}

export default connect(null, {getOffers})(
    LoanPayoutsPage
);
