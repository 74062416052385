import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Plan from "@wisetack/shared-ui/components/Plan";
import PlanFmp from "@wisetack/shared-ui/components/PlanFmp";
import {acceptOffer, getOffers, getSettings} from "../store/actions/consumerActions";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {nextPageForStatus} from "./ConsumerEntryPage.js";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import classNames from "classnames";
import styles from "./ConsumerChoosePlanPage.module.scss";
import Modal from "@wisetack/shared-ui/components/Modal";
import info from "@wisetack/assets/img/info-icon.svg";
import {PartnerOfferFooter} from "../components/PartnerOfferFooter";
import uuid from "uuid";
import {getAccessToken} from "../utils/storage";

const pageName = "Offers Page";

class ConsumerChoosePlanPage extends React.Component {

    state = {
        settingsRequestFinished: false,
        offersRequestFinished: false,
        acceptRequestId: null
    }

    isPayoutsListFound() {
        return this.props.payouts && this.props.payouts.length > 1
    }

    isOnePayoutFound() {
        return this.props.payouts && this.props.payouts.length === 1
    }

    navigateToReviewPlan() {
        if (!this.props.partnerAllocationMap || !this.props.partnerAllocationMap.confirmed) {
            return false;
        }
        const confirmedPartner = this.props.partnerAllocationMap.confirmed
        if (!this.props.partnerDecisionMap || !this.props.partnerDecisionMap[confirmedPartner]) {
            return false;
        }
        // SQC-383
        const decisions = this.props.partnerDecisionMap[confirmedPartner]
        for (const decision of decisions) {
            // if partner purchase authorization step reached - offer already selected
            if (decision.operation === "AUTHORIZE_PURCHASE") {
                this.props.history.push("/review_plan")
                return true
            }
        }
        return false
    }

    navigateToPayouts() {
        if (this.isPayoutsListFound()) {
            for (const payout of this.props.payouts) {
                // if one of the payouts approved (it means other offer can't be selected)
                // navigate to the payouts list
                if (['DOCUMENTS_SIGNED', 'OFFER_CONVERTED', 'SETTLED'].includes(payout.status)) {
                    this.props.history.push("/loan_payouts_return");
                    return true;
                }
            }
        } else if (this.isOnePayoutFound()) {
            const payout = this.props.payouts[0];
            if (payout.status === 'DECLINED' && payout.path) {
                this.props.history.push(payout.path);
                return true;
            }
        }
        return false;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName,
        };
        logAmplitudeEvent(pageName, this.logProps);
        this.props.getOffers(this.props.loanAppId);
        this.props.getSettings(this.props.loanAppId, getAccessToken(this.props.loanAppId));
    }

    componentDidUpdate(prevProps) {
        if (this.props.settingsDataRequestId && this.props.settingsDataRequestId !== prevProps.settingsDataRequestId) {
            this.setState({settingsRequestFinished: true})
        }
        if (this.props.getOffersRequestId && this.props.getOffersRequestId !== prevProps.getOffersRequestId) {
            this.setState({offersRequestFinished: true})
        }
        if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
            return;
        }
        if (this.props.errorMessage) {
            Log.info(this.props.errorMessage, `error`);
            this.props.history.push("/error");
            return;
        }
        if (this.props.partnerPending || this.props.extendedFraudAlert) {
            this.props.history.push("/pending");
            return;
        }
        if (this.navigateToPayouts() || this.navigateToReviewPlan()) {
            return;
        }
        if (this.props.offerAcceptedAt !== prevProps.offerAcceptedAt) {
            // add logic here to route to another page depending from status
            switch (this.props.selectedLoanOfferStatus) {
                case "SELECTED":
                    const lockRequired = this.props.lockRequired;
                    if (lockRequired) {
                        this.props.history.push("/offer_lock");
                    } else if (this.isPartnerAllocationChanged()) {
                        if (this.props.firstMonthPrepayment && !this.props.cardLastFourDigits) {
                            this.props.history.push("/card_collection");
                        } else {
                            this.props.history.push("/link_bank");
                        }
                    } else if (this.props.status === "CONDITIONAL_APPROVAL" && this.props.firstMonthPrepayment) {
                        this.props.history.push("/card_collection", { from: "choose_plan" } );
                    } else if (!this.props.autoPaymentsDecision && (this.props.status === "OFFER_AVAILABLE" ||
                        this.props.status === "CONDITIONAL_APPROVAL" ||
                        this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED")) {
                        this.props.history.push("/link_bank");
                    } else { //when reentering from same flow.
                        // Use same logic as entryPage. Be careful as it stays in choose_plan
                        let page = nextPageForStatus(this.props);
                        if (page[0]) {
                            this.props.history.push(page[0]);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    handleOnPlanClick = (plan) => {
        if (this.state.acceptRequestId) {
            return;
        }
        Log.info(plan, `Plan clicked`);
        logAmplitudeEvent("Offer Selected", {
            ...this.logProps,
            offersCount: this.props.plans.length,
            offerSelectedDuration: plan.months,
            selectedInterestFreeOffer: plan.apr === 0,
            partner: plan.partner
        });
        const acceptRequestId = this.state.acceptRequestId || 'i-' + uuid.v4();
        this.setState({acceptRequestId})
        this.props.acceptOffer(this.props.loanAppId, plan.id, plan.partner, acceptRequestId);
    };

    isPartnerAllocationChanged() {
        if (!this.props.partnerAllocationMap || !this.props.partnerAllocationMap.confirmed) {
            return false;
        }
        return this.props.partnerAllocationMap.allocated !== this.props.partnerAllocationMap.confirmed
    }

    isReturningCustomer() {
        return this.props.plans && this.props.plans[0].partnerOperation === 'RETURNING_CUSTOMER'
    }

    render() {
        const {
            status,
            errorMessage,
            plans,
            expectedRunLength,
            firstMonthPrepayment,
        } = this.props;

        let approvedAmount;
        if (plans) {
            approvedAmount = plans.map(p => p.approvedAmount).find(a => a != null);
        }

        const showContent = approvedAmount && status && !errorMessage &&
            this.state.settingsRequestFinished && this.state.offersRequestFinished && !this.props.isLoading;

        return (
            <Container>
                <PageHeader progress="42%">
                    {showContent ? (
                        <Fragment>
                            <div>
                                You’re{" "}
                                {plans[0].partner === 'CITIZENS' && !this.isReturningCustomer() ? "eligible" : status === "CONDITIONAL_APPROVAL" ? "qualified" : "approved"}{" "}
                                for {formatAmount(approvedAmount)}!
                            </div>
                            <div>
                                {status === "CONDITIONAL_APPROVAL" && (
                                    <>
                                        Your application isn't complete yet. <br/>
                                    </>
                                )}
                                {this.isPayoutsListFound() ? (
                                        <div>Choose an option.<sup style={{fontSize: 12}}>&#x2020;</sup></div>) : plans[0].partner ?
                                    <div>Choose an offer.</div> : <div>Choose an option.</div>
                                }
                                <span data-toggle="modal"
                                       data-target="#firstPaymentInfoModal"
                                       onClick={() => {
                                         logAmplitudeEvent("Clicked First Payment Info of Consumer Choose Plan Page", this.logProps);
                                       }}
                                       className={classNames("material-icons", firstMonthPrepayment ? styles.infoIcon : styles.hideInfoIcon)}>info_outline</span>
                                <Modal id={'firstPaymentInfoModal'}>
                                  <div className={styles.modal_text}>
                                    <img className={styles.logo} src={info} alt="info.svg"/>
                                    <div>
                                      The amount you are qualified for includes the first payment due at loan start.
                                    </div>
                                  </div>
                                </Modal>
                            </div>
                        </Fragment>
                    ) : null}
                    {showContent ? (
                        <div className={styles.choosePlanSubtitle}>
                            No origination, prepayment, or late fees.
                        </div>
                    ) : null}
                    <></>
                </PageHeader>
                <LoaderWithMessage isLoading={!this.state.offersRequestFinished ||
                    !this.state.settingsRequestFinished || this.props.isLoading}
                                   duration={expectedRunLength}/>
                <Error pageName={pageName}>{errorMessage}</Error>
                <div style={{paddingBottom: "15px"}}>
                    {showContent && !firstMonthPrepayment &&
                        plans.map((item) => (
                            <Plan
                                key={item.id}
                                item={item}
                                onClick={() => this.handleOnPlanClick(item)}
                            />
                        ))}
                    {showContent && firstMonthPrepayment &&
                        plans.map((item) => (
                            <PlanFmp
                                key={item.id}
                                item={item}
                                onClick={() => this.handleOnPlanClick(item)}
                            />
                        ))}
                </div>
                <div>
                    {this.isPayoutsListFound() && showContent ? (
                            <div style={{textAlign: "center", fontSize: 13}}><sup>&#x2020;</sup>
                                If you are paying the merchant in stages,
                                your monthly payments will vary depending on the amount disbursed and time of release.
                            </div>) :
                        <div></div>
                    }
                    {plans[0] && plans[0].partner && showContent ?
                        <PartnerOfferFooter/> : <div></div>
                    }
                </div>
            </Container>
        )
    }
}

ConsumerChoosePlanPage.propTypes = {
    getOffers: PropTypes.func.isRequired,
    acceptOffer: PropTypes.func.isRequired,
    getSettings: PropTypes.func.isRequired,
    plans: PropTypes.array.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    extendedFraudAlert: state.consumer.extendedFraudAlert,
    partnerPending: state.consumer.partnerPending,
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    errorMessage: state.consumer.errorMessage,
    isLoading: state.consumer.isLoading,
    status: state.consumer.status,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    moreInfoRequired: state.consumer.moreInfoRequired,
    offerAcceptedAt: state.consumer.offerAcceptedAt,
    plans: state.consumer.plans,
    payouts: state.consumer.payouts,
    transactionAmount: state.consumer.transactionAmount,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    autoPaymentsDecision: state.consumer.autoPaymentsDecision,
    partnerAllocationMap: state.consumer.partnerAllocationMap,
    partnerDecisionMap: state.consumer.partnerDecisionMap,
    expectedRunLength: state.consumer.expectedRunLength,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment,
    settingsDataRequestId: state.consumer.settingsDataRequestId,
    getOffersRequestId: state.consumer.getOffersRequestId,
    cardLastFourDigits: state.consumer.cardLastFourDigits
});

export default connect(mapStateToProps, {getSettings, getOffers, acceptOffer})(
    ConsumerChoosePlanPage
);
