import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerPurchaseCompletePage.module.scss";
import exclamationIcon from "@wisetack/assets/img/icon-exclamation.svg";
import moment from "moment";
import {getOffers} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import {getVerticalFragment} from "../utils/verticalMessages";
import classNames from "classnames";
import {formatDate} from "../utils/format";
import CardCallbox from "@wisetack/shared-ui/components/CardCallbox";
import StandardLearnMoreContent from "../components/StandardLearnMoreContent";

const pageName = "Purchase Complete Page"

class ConsumerPurchaseCompletePage extends React.Component {

  state = {}

  componentDidMount() {
    if (this.navigateToPayouts()) {
      return;
    }

    window.scrollTo(0, 0);

    logAmplitudeEvent(pageName, {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    });

    if ((!this.props.selectedPlan || Object.keys(this.props.selectedPlan).length === 0) && this.props.loanAppId) {
      this.props.getOffers(this.props.loanAppId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
    }
    this.navigateToPayouts();
  }

  hasMultiplePayouts() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  navigateToPayouts() {
    if (!this.hasMultiplePayouts()) {
      return false;
    }
    for (const payout of this.props.payouts) {
      if (payout.status !== "OFFER_CONVERTED" && payout.status !== "SETTLED") {
        this.props.history.push("/loan_payouts");
        return true;
      }
    }
    return false;
  }

  handleOnBack = () => {
    this.props.history.push("/loan_payouts");
  }

  render() {
    const {
      selectedPlan,
      isLoading,
      errorMessage,
      merchantName,
      autoPayments,
      loanServicer,
      vertical,
      settlementDelay,
      settlementDate,
      firstMonthPrepayment,
      cardLastFourDigits
    } = this.props;
    Log.info(this.props, `ConsumerPurchaseCompletePage props`);
    let date = "";

    const plan = selectedPlan || {};
    if (plan.starting) {
      const starting = moment(plan.starting, "MM/DD/YYYY");
      if (starting.isValid()) {
        date = starting.format("LL");
      }
    }

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
          <div>
            <Loader/>
            <div className={styles.message}>Wait a moment please...</div>
          </div>
      );
    };

    const bullet_point_1 = (merchantName, vertical, settlementDate, settlementDelay) => {

      if (vertical === 'LG' && settlementDelay) {
        return <li>
          <b>{merchantName}</b> will receive the funds on <span style={{whiteSpace: 'nowrap'}}><b>{formatDate(settlementDate, 'MM-DD-YYYY', 'MMM DD, YYYY')}</b></span>
        </li>
      }

      return <li>
        <b>{merchantName}</b> will receive the funds.<br/>
      </li>
    }

    const bullet_point_2 = (settlementDelay) => {
      if (settlementDelay) {
        return <li>
          Interest will not accrue until we send the funds
        </li>
      }
    }

    const bullet_point_3_standard = (loanServicer, firstMonthPrepayment) => {
      return <li>
        {firstMonthPrepayment ? <>You'll get an email from us to manage all remaining loan payments.</> :
            loanServicer === 'Peach' ?
                <>You'll get an email from us to manage your financing payments.</> :
                <>You'll receive an email from <b>{loanServicer}</b>, where you can manage your financing and make
                  payments.</>
        }
        {
            <>
            <span className={styles.learnMore}
                  data-toggle="modal"
                  data-target="#standardLearnMoreModal"
                  onClick={() => {
                    logAmplitudeEvent("Opened Learn more link", this.logProps);
                  }}
            >
              &nbsp;<b>Learn more.</b>
            </span>
              <br/>
            </>
        }
      </li>
    }

    const bullet_point_3_citizens = () => {
      return <li>
        <>You'll get an email from <b>Citizens Pay</b> regarding your payment due date and managing your repayments.</>
        <>
            <span className={styles.learnMore}
                  data-toggle="modal"
                  data-target="#citizensLearnMoreModal"
                  onClick={() => {
                    logAmplitudeEvent("Opened Learn more link", this.logProps);
                  }}
            >
              &nbsp;<b>Learn more.</b>
            </span>
          <br/>
        </>
      </li>
    }

    const bullet_point_4 = (date, settlementDelay) => {
      if (settlementDelay) {
        return <li>
          Your first payment will be due on <b>{date}</b>.
        </li>
      }
      return <li>
        Your first payment is due on <b>{date}</b>.
      </li>
    }

    const StandardLearnMoreModal = () => {
      return <LearnMoreModal id={"standardLearnMoreModal"}>
        <StandardLearnMoreContent loanServicer={this.props.loanServicer} />
      </LearnMoreModal>
    }

    const CitizensLearnMoreModal = () => {
      return <LearnMoreModal id={"citizensLearnMoreModal"}>
        <p>Congratulations on your approval for a <strong>Citizens Pay Line of Credit!</strong></p>
        <p>Keep an eye out for a Citizens Pay welcome email with details on how to:</p>
        <ul>
          <li>See transactions</li>
          <li>Make payments</li>
          <li>View statements</li>
          <li>Edit your information</li>
        </ul>
      </LearnMoreModal>
    }

    const CallboxMessage = ({date, cardLastFourDigits}) => {
      return (
          <>
            Your first installment will be charged on <b>{date}</b>.
            We have used the card on file ending in *{cardLastFourDigits} to make the authorization today.
          </>
      )
    }

    const showContent = !isLoading && !errorMessage;

    const NoContent = () => {
      return (
          <Container>
            <PageHeader progress="100%" displayChildren={showContent}/>
            <LoaderWithMessage/>
            <Error pageName={pageName}>{errorMessage}</Error>
            <div style={{paddingBottom: "15px"}}/>
          </Container>
      )
    }

    const StandardHeader = () => {
      return (
          <PageHeader progress="100%">
            <Fragment>Hooray! Purchase Confirmed!
              <h5 className={styles.h5}>See what's next.</h5>
            </Fragment>
            <Fragment/>
          </PageHeader>
      )
    }

    const PayoutHeader = () => {
      return (
          <PageHeader progress="100%">
            <Fragment>Hooray! Purchase Confirmed!
            </Fragment>
            <Fragment/>
          </PageHeader>
      )
    }

    const AutoPayEnabled = () => {
      return (
          <div className={styles.autoPaymentsMessage}>
            <span className="material-icons" style={{ fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
            <b> You've enabled automatic payments</b>
          </div>
      )
    }

    const StandardBulletContent = () => {
      return (
          <>
            {firstMonthPrepayment &&
                <CardCallbox><CallboxMessage date={date} cardLastFourDigits={cardLastFourDigits}/></CardCallbox>}
            <div className={styles.innerText}>
              {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
              {bullet_point_2(settlementDelay)}
              {bullet_point_3_standard(loanServicer, firstMonthPrepayment)}
              {!firstMonthPrepayment && bullet_point_4(date, settlementDelay)}
            </div>
            {autoPayments === 'ENABLED' && <AutoPayEnabled />}
          </>
      )
    }

    const CitizensBulletContent = () => {
      return (
          <div className={styles.innerText}>
            {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
            {bullet_point_3_citizens()}
          </div>
      )
    }

    const ContentFooter = () => {
      return (
          <div className={styles.support}>
            <img className={styles.image} src={exclamationIcon} alt="exclamation-mark"/>
            <div>
              <h3>{getVerticalFragment('purchase_complete_header', this.props.vertical)}</h3>
              <div>
                {getVerticalFragment('purchase_complete_message', this.props.vertical, {merchantName})}
              </div>
            </div>
          </div>
      )
    }

    const PayoutsContent = () => {
      return (
          <Container>
            <PayoutHeader/>
            <StandardLearnMoreModal />
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  {autoPayments !== 'ENABLED' &&
                      <h5>What happens next?</h5>
                  }
                  <StandardBulletContent />
                  <button
                      data-test-id="purchase-complete-back"
                      className={classNames("btn", styles.buttonEnabled)}
                      onClick={this.handleOnBack}
                  >
                    BACK TO LOAN PAYOUTS
                  </button>
                  <ContentFooter />
                </div>
              </div>
            </div>
          </Container>
      )
    }

    const StandardLoanAppContent = () => {
      return (
          <Container>
            <StandardLearnMoreModal />
            <StandardHeader/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  <StandardBulletContent />
                  <ContentFooter />
                </div>
              </div>
            </div>
          </Container>
      )
    }

    const CitizensContent = () => {
      return (
          <Container>
            <CitizensLearnMoreModal />
            <StandardHeader/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  <CitizensBulletContent />
                  {autoPayments === 'ENABLED' && <AutoPayEnabled />}
                  <ContentFooter />
                </div>
              </div>
            </div>
          </Container>
      )
    }

    if (!showContent) {
      return <NoContent />
    } else if (selectedPlan.partner === "CITIZENS") {
      return <CitizensContent />
    } else if (this.hasMultiplePayouts()) {
      return <PayoutsContent />
    } else {
      return <StandardLoanAppContent/>
    }
  }
}

ConsumerPurchaseCompletePage.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  selectedPlan: state.consumer.selectedPlan,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  offerId: state.consumer.selectedLoanOfferId || state.consumer.offerId,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  initExpired: state.consumer.initExpired,
  autoPayments: state.consumer.autoPaymentsDecision,
  payouts: state.consumer.payouts,
  loanServicer: state.consumer.loanServicer,
  vertical: state.consumer.vertical,
  settlementDelay: state.consumer.settlementDelay,
  settlementDate: state.consumer.settlementDate,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment,
  cardLastFourDigits: state.consumer.cardLastFourDigits
});

export default connect(
    mapStateToProps,
    {getOffers}
)(ConsumerPurchaseCompletePage);
