import React from "react";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import PaymentCalculator from "@wisetack/shared-ui/components/PaymentCalculator";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

const pageName = "Payment Calculator Page"

class PaymentCalculatorPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
          page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
    }
    
    render() {

        return (
            <Container>
                <PageHeader progress="">
                    <div style={{marginTop: "-20px", marginBottom: "40px"}}>Payment Calculator</div>
                    <div></div>
                </PageHeader>
                <PaymentCalculator />
            </Container>
        )
    }
}
  
export default PaymentCalculatorPage;