import React from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import usePayoutFlow from "../hooks/usePayoutFlow";
import {getVerticalFragment} from "../utils/verticalMessages";
import styles from "./ConsumerConfirmationPage.module.scss";
import plane from "@wisetack/assets/img/paper-plane.svg";
import moment from "moment";
import usePayoutLog from "../hooks/usePayoutLog";

function LoanPayoutConfirmReminderPage() {
    const history = useHistory();

    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);
    const vertical = useSelector(state => state.consumer.vertical);
    const doingBusinessAs = useSelector(state => state.consumer.doingBusinessAs);
    const merchantName = useSelector(state => state.consumer.merchantName);

    const payoutData = usePayoutFlow(selectedPlan, selectedPayout);

    const logAmplitude = usePayoutLog("Loan Payout Confirm Reminder Page")

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/loan_payouts")
    }

    if (payoutData.serviceCompletionProvidedByBorrower) {
        return <Container>
            <PageHeader progress="100%" onBack={handleOnBack}>
                <div>
                    Great! We’ll send you a reminder to&nbsp;come&nbsp;back
                </div>
                <div/>
            </PageHeader>
            <div className={styles.content}>
                <div style={{ textAlign: "center", paddingTop: "5px" }}>
                    <img className={styles.image} src={plane} alt="paper-plane" />
                </div>
                {getVerticalFragment('confirm_remainder_with_date_message', vertical, {
                    merchantName: doingBusinessAs ? doingBusinessAs : merchantName,
                    completionDate: moment(payoutData.serviceCompletionProvidedByBorrower, "YYYY-MM-DD").format("LL")
                })}
            </div>
        </Container>
    }

    return <Container>
        <PageHeader progress="100%" onBack={handleOnBack}>
            {getVerticalFragment('confirm_remainder_without_date_header', vertical)}
            <div/>
        </PageHeader>
        <div className={styles.content}>
            <div style={{ textAlign: "center", paddingTop: "5px" }}>
                <img className={styles.image} src={plane} alt="paper-plane" />
            </div>
            {getVerticalFragment('confirm_remainder_without_date_message', vertical, {
                merchantName: doingBusinessAs ? doingBusinessAs : merchantName
            })}
            <p>
                Your offer is locked-in until <strong>{moment(payoutData.expDate, "MM DD YYYY").format("LL")}</strong>.
            </p>
        </div>
    </Container>
}

export default connect(null, {})(
    LoanPayoutConfirmReminderPage
);
