import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerRejectedPage.module.scss";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

class ConsumerRejectedPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Reject Page", {});
    }

    getReasonText(reason) {
        switch (reason) {
            case "UNSUPPORTED_STATE":
                return "Our services are not yet available in your state";
            case "UNDER_MINIMUM_AGE":
                return "You are below the legal age for our service";
            default:
                return null;
        }
    }

    rejectReasonsList() {
        if (this.props.rejectReasonsList) {
            return this.props.rejectReasonsList.map(reason => {
                return <p key={reason}><span>{this.getReasonText(reason)}</span></p>
            })
        }
    }

    modalOnClick = eventType => () => {
        logAmplitudeEvent(eventType, {
            pageName: "decline page"
        });
    };

    render() {
        return (
            <Container>
                <PageHeader progress="99%">
                    <Fragment>
                        Unfortunately, we are unable to approve your application
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                {(!this.props.rejectReasonsList || this.props.rejectReasonsList.length === 0) &&
                    <div className={styles.content}>
                        <p>We couldn't approve your application for the following reasons.</p>
                        <p><span>You are not eligible for this product</span></p>
                        <p>We are grateful you considered us, and hope we can serve you in the future.</p>
                    </div>
                }
                {this.props.rejectReasonsList && this.props.rejectReasonsList.length === 1 &&
                    <div className={styles.content}>
                        <p>We couldn't approve your application for the following reason.</p>
                        {this.rejectReasonsList()}
                        <p>We are grateful you considered us, and hope we can serve you in the future.</p>
                    </div>
                }
                {this.props.rejectReasonsList && this.props.rejectReasonsList.length > 1 &&
                    <div className={styles.content}>
                        <p>We couldn't approve your application for the following reasons.</p>
                        {this.rejectReasonsList()}
                        <p>We are grateful you considered us, and hope we can serve you in the future.</p>
                    </div>
                }
            </Container>
        );
    }
}

ConsumerRejectedPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  status: state.consumer.status,
  rejectReasonsList: state.consumer.rejectReasonsList
});

export default connect(
  mapStateToProps,
  {}
)(ConsumerRejectedPage);
