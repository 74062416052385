import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import image from "@wisetack/assets/img/hello.svg";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./PrequalBackPage.module.scss";
import {PrequalInfoPageBottom} from "../components/PrequalInfoPageBottom";


function PrequalBackPage() {
    const history = useHistory();

    const handleSubmit = () => {
        history.push("/choose_plan")
    }

    return <Container>
        <PageHeader progress="50%">
            <div>Welcome back!</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="hello-logo"/>
        <div className={styles.message}>
            You are already prequalified. Continue to see your financing options.
        </div>
        <PrequalInfoPageBottom
            onButtonClick={handleSubmit}
            display={true}
            btnDisabled={false}
            disableLast={true}
        />
    </Container>

}

export default connect(null, {})(
    PrequalBackPage
);
