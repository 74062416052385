import React from "react";
import classNames from "classnames";

import styles from "./LoanInfoItem.module.scss";
import {formatAmount} from "@wisetack/shared-ui/utils/format";


const PayoutItem = ({payout}) => {
    const textColor = {
        [styles.grey]: payout.statusName === 'Canceled',
        [styles.dark]: payout.statusName !== 'Canceled'
    }

    const iconClasses = {
        "material-icons": true,
        [styles.payout_icon]: true
    }
    if (payout.status === "OFFER_CONVERTED" || payout.status === "SETTLED") {
        iconClasses[styles.mint] = true
    } else {
        iconClasses[styles.grey] = true
    }

    return <div>
        <div>
            { payout.statusName === 'Canceled' ?
                <span className={classNames("material-icons", styles.payout_icon_cancel)}>highlight_off</span> :
                <span className={classNames(iconClasses)}>check_circle_outlined</span>
            }
            <span className={classNames(textColor, styles.payout_name)}>{payout.payoutName}</span>
            { payout.statusName === 'Canceled' &&
                <span className={classNames(textColor)}> - {payout.statusName}</span>
            }
        </div>
        <div className={classNames(textColor, styles.payoutAmount)}>{formatAmount(payout.approvedLoanAmount)}</div>
    </div>
}

const PayoutsList = ({payouts}) => {
    if (!payouts || payouts.length < 2) {
        return null;
    }
    return <div className={styles.payoutsList}>
        <hr/>
        <div className={styles.payoutItems}>
            { payouts.map(item => (
                <PayoutItem key={item.id} payout={item}/>
            ))}
        </div>
    </div>
}

export function LoanInfoItem({item, onClick}) {
    const classes = {
        [styles.nav]: true,
        [styles.mint]: true
    }
    return <div onClick={onClick} className={styles.details}>
        <div className={classNames(classes)}>
            <span className={styles.amount}>{item.amount}</span>
            <span className={styles.delim}>|</span>
            <span className={styles.status}>{item.statusName}</span>
            <span className={classNames("material-icons", styles.open_icon)}>arrow_forward_ios</span>
        </div>
        <div className={styles.exp}>Exp. Date: {item.expDate}</div>
        <PayoutsList payouts={item.loanPayoutDetailsList}/>
    </div>
}
