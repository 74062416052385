import React from "react";
import classNames from "classnames";
import uuid from "uuid";
import styles from "./ConsumerPhonePage.module.scss";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import SignupFooter from "@wisetack/shared-ui/components/SignupFooter";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Log from "@wisetack/shared-ui/utils/Log";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitPhone, setError } from "../store/actions/consumerActions";
import Form from "@wisetack/shared-ui/components/Form";
import { BorrowerFieldValidator } from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import { formatUsPhone } from "@wisetack/shared-ui/utils/format";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import Loader from "@wisetack/shared-ui/components/PulseLoader";

class ConsumerPhonePage extends React.Component {

    getPreFill() {
        if (this.props.fieldsValue && this.props.fieldsValue.phone) {
            return formatUsPhone(this.props.fieldsValue.phone);
        }
        if (this.props.mobileNumber) {
             return formatUsPhone(this.props.mobileNumber);
        }
        return "";
    }

    state = {
        mobileNumber: this.getPreFill(),
        errors: {},
        suggestions: {}
    };

    constructor(props) {
        super(props);
        this.validator = new BorrowerFieldValidator(this.pageName())
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let pageName = this.pageName()
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
        this.validator.props = this.logProps;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.errorMessage) {
            return {
                suggestions: { phone: nextProps.errorMessage }
            }
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (this.props.errorMessage && this.props.errorMessage.includes('lockout')) {
            this.props.setError(null);
            this.props.history.push("/account_locked");
            return;
        }
        if (this.props.submitPhoneRequestId && this.props.submitPhoneRequestId !== prevProps.submitPhoneRequestId) {
            if (this.props.errorMessage) {
                // on error stay on this page
                return;
            }
            if (this.props.fieldsRequired && !this.props.fieldsRequired.phone) {
                if (this.getMode() === 'returnLogin') {
                    this.props.history.push("/pin");
                } else {
                    this.props.history.push("/phone_pin");
                }
            }
        }
    }

    pageName = () => {
        switch (this.getMode()) {
            case 'firstLogin':
                return "First Time Mobile Entry Page"
            case 'retryLogin':
                return "Code Retry Page"
            case 'returnLogin':
                return "Return Mobile Entry Page"
            default:
                return "Mobile Entry Page"
        }
    }

    buttonDisabled = () => {
        if (!this.state.mobileNumber) return true;
        if (/[0-9]/.test(this.state.mobileNumber[0])) {
            const number = this.state.mobileNumber.replace(/-/g, "");
            if (number.length < 10) return true;
            if (number.length === 10) return false;
            if (number.length === 11 && number[0] === "1") return false;
        }
    };

    validatePhone = phone => {
        return this.validator.validateMobileNumber(phone);
    };

    formatMobileNumber = mobileNumber => {
        mobileNumber = mobileNumber.replace(/\D/g, "");
        if (mobileNumber.length === 10) {
            return "+1" + mobileNumber;
        } else if (mobileNumber.length === 11) {
            return "+" + mobileNumber;
        }
        return mobileNumber;
    };

    handleOnChange = e => {
        let value = e.target.value;
        if (value.length > 0) {
            value = value.replace(/[^0-9]/g, "");
            // see if first digit is a number.
            if (/[0-9]/.test(value[0])) {
                value = formatUsPhone(value.match(/[0-9]+/g).join(""));
            }
        }
        this.setState({ mobileNumber: value });
    };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    handleOnBlur = e => {
        let val = e.target.value;
        let name = e.target.name;
        const err = this.validatePhone(val);
        if (err) {
            this.setError(name, err);
        }
    };

    handleSubmit = e => {
        const requestId = uuid.v4();
        logAmplitudeEvent("Pressed Send Code Button", this.logProps);
        this.props.submitPhone(
            this.props.loanAppId, 
            this.formatMobileNumber(this.state.mobileNumber),
            this.getMode(),
            requestId
        );
    };

    getMode = () => {
        if (this.props.location.pathname === '/phone') {
            return 'firstLogin'
        }
        if (this.props.location.pathname === '/phone_retry') {
            return 'retryLogin'
        }
        if (this.props.location.pathname === '/phone_return') {
            return 'returnLogin'
        }
    }

    render() {

        Log.info(this.state, `render state`);

        let btnDisabled = this.buttonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        const LoaderWithMessage = () => {
            if (!this.props.isLoading) return null;
            return (
                <div>
                    <Loader />
                    <div className={styles.message}>Wait a moment please...</div>
                </div>
            );
        };

        const HeaderContent = () => {
            switch (this.getMode()) {
                case 'firstLogin':
                    return <PageHeader progress="0.3%">
                        <div>It’s quick to see your options</div>
                        <div className={styles.pageHeader}>We just need to verify a few things before you can pay <b>{this.props.merchantName}</b> over time.
                            Enter your mobile number to continue.</div>
                    </PageHeader>
                case 'retryLogin':
                    return <PageHeader progress="0.3%">
                        <div>Let’s try verifying your phone number again</div>
                        <div>Enter a phone number where you can receive text&nbsp;messages.</div>
                    </PageHeader>
                case 'returnLogin':
                    return <PageHeader progress="0.3%">
                        <div>Welcome back!</div>
                        <div>Enter the phone number associated with your loan application. We'll send you a verification code to&nbsp;regain&nbsp;access.</div>
                    </PageHeader>
                default:
                    return <PageHeader progress="0.3%">
                        <div></div>
                        <div></div>
                    </PageHeader>
            }
        };

        const OptionsContent = () => {
            switch (this.getMode()) {
                case 'firstLogin':
                    return <ul className={styles.options}>
                        <li>Rates from 0% APR</li>
                        <li>No hidden fees</li>
                        <li>Checking eligibility won’t affect your credit score</li>
                    </ul>
                case 'retryLogin':
                    return <ul className={styles.options}>
                        <li>Must be the applicant's number</li>
                        <li>A mobile phone you can access now</li>
                    </ul>
                default:
                    return <div className={styles.options}/>
            }
        };

         const FooterContent = () => {
             return this.getMode() === 'firstLogin' &&  <SignupFooter />
        };

        return (
            <>
            <Container>
                <div className={styles.consumerPageWrapper}>
                <HeaderContent />
                <OptionsContent />
                <LoaderWithMessage />
                <div>
                    {!this.props.isLoading && (
                        <>
                            <Form>
                                <FormRow>
                                    <FormInput
                                        type="text"
                                        name="phone"
                                        label="Mobile number"
                                        value={this.state.mobileNumber}
                                        onChange={this.handleOnChange}
                                        onBlur={this.handleOnBlur}
                                        errors={this.state.errors}
                                        suggestions={this.state.suggestions}
                                    />
                                </FormRow>
                            </Form>
                            <div className={classNames("row", styles.buttonRow)}>
                                <div className="col">
                                    <button className={btnClasses} onClick={this.handleSubmit}>
                                        <span className="material-icons" style={{ fontSize: "16px", marginRight: "5px", paddingBottom: "3px" }}>
                                            lock_outline
                                        </span>
                                        SEND CODE
                                    </button>
                                </div>
                            </div>
                            <p className={styles.verificationMessage}>We’ll send you a verification code.</p>
                        </>
                    )}
                </div>
                <FooterContent/>
                </div>
            </Container>
            </>
        );
    }
}

ConsumerPhonePage.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    mobileNumber: state.consumer.mobileNumber,
    fieldsRequired: state.consumer.fieldsRequired,
    fieldsValue: state.consumer.fieldsValue,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage,
    submitPhoneRequestId: state.consumer.submitPhoneRequestId
});

export default connect(
    mapStateToProps,
    { submitPhone, setError }
)(ConsumerPhonePage);