import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {connect} from "react-redux";
import classNames from "classnames";

import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image from "@wisetack/assets/img/info-icon.svg";
import styles from "./OffersUpdatedPage.module.scss";

function OffersUpdatedPage() {
    const history = useHistory();

    const handleSubmit = () => {
        logAmplitudeEvent("See new offers button clicked", {page: "Offers Updated Page"});
        history.push("/choose_plan")
    }

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        [styles.buttonEnabled]: true
    });

    useEffect(() => {
        logAmplitudeEvent("Offers Updated Page", {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Container>
        <PageHeader progress="70%">
            <div>Your attention is required</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="info-icon"/>
        <div className={styles.message}>
            Your offers have been updated. Please review and select a new offer.
        </div>
        <button
            data-test-id="continue"
            className={btnClasses}
            onClick={handleSubmit}
        >
            SEE NEW OFFERS
        </button>
        <div className={styles.bottom}>
            Don’t worry, we don’t check your credit again.
        </div>
    </Container>
}

export default connect(null, {})(
    OffersUpdatedPage
);
