import React from "react";
import {connect} from "react-redux";
import uuid from "uuid";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import PaymentAuthModal from "@wisetack/shared-ui/components/PaymentAuthModal";

import ConsumerLinkBankReadMoreModal from "@wisetack/shared-ui/components/ConsumerLinkBankLearnMoreModal";
import classNames from "classnames";
import styles from "./ConsumerLinkBankPage.module.scss";
import image from "@wisetack/assets/img/piggy-bank.svg";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import {acceptPlaidToken, getLoanStatus} from "../store/actions/consumerActions";
import warnImage from "@wisetack/assets/img/warning-icon.svg";

const pageName = "Autopay Page (bank link required)"

class AutopayConfirmationPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName
        }
        logAmplitudeEvent(pageName, this.logProps);
    }

    componentDidUpdate(prevProps) {
        if (this.props.acceptPlaidTokenRequestId && this.props.acceptPlaidTokenRequestId !== prevProps.acceptPlaidTokenRequestId) {
            if (this.props.errorMessage) {
                Log.info(this.props.errorMessage, `error`);
                this.props.history.push("/error");
                return;
            }
            if (this.props.status === "CONDITIONAL_APPROVAL") {
                if (this.props.moreInfoRequired) {
                    this.props.history.push("/moreinfo");
                    return;
                }
                if (this.props.extendedFraudAlert) {
                    this.props.history.push("/pending");
                    return;
                }
                if (this.props.autoPaymentsDecision) {
                    this.props.history.push("/review_plan");
                    return;
                }
            }
        }
    }

    handleOnSkipButtonClick = () => {
        logAmplitudeEvent("Pressed Skip Button", this.logProps);
        this.setAutoPay("DISABLED");
    };
    
    handleOnButtonClick = () => {
        logAmplitudeEvent("Pressed Automate Payments Button", this.logProps);
        this.setAutoPay("ENABLED");
    };

    setAutoPay = decision => {
        const acceptPlaidTokenRequestId = uuid.v4();
        this.props.acceptPlaidToken(
            this.props.loanAppId,
            this.props.offerId,
            null,
            null,
            null,
            decision,
            acceptPlaidTokenRequestId
        );
    } 

    render() {
        Log.info(this.props, `ConsumerLinkBankPage props`);

        const btnFinishClasses = classNames({
            btn: true,
            "btn-block": true,
            [styles.buttonFinish]: true
        });

        const btnSkipClasses = classNames({
            [styles.buttonSkip]: true
        });

        const LoaderWithMessage = () => {
            if (!this.props.isLoading) return null;
            return (
                <div>
                    <Loader />
                    <div className={styles.message}>Wait a moment please...</div>
                </div>
            );
        };

        const SubHeaderContent = () => {
            return <>
                {this.props.selectedPlan.partner === 'CITIZENS' &&
                    <div className={styles.text}>
                        By setting up automatic monthly payments, I acknowledge that I have accessed, read and agree to
                        the
                        <a style={{fontWeight: 600}}
                           onClick={() => {logAmplitudeEvent("Pressed Recurring Payment\n" +
                               "Terms and Conditions Link", this.logProps)
                           }}
                           href="https://www.citizensbank.com/disclosures/recurring-payment.aspx"
                           target="_blank"
                           rel="noopener noreferrer">Recurring Payment Terms and Conditions</a>.
                    </div>}
                {(this.props.selectedPlan.partner !== 'CITIZENS') &&
                    <div className={styles.text}> By setting up automatic monthly payments, I acknowledge that
                        I have accessed, read and agree to the{" "}
                        <span
                            data-toggle="modal"
                            data-target="#paymentAuth"
                            onClick={() => {
                                logAmplitudeEvent("Pressed Payment Authorization Link", this.logProps);
                            }}
                        >
                            payment authorization.
                         </span>
                    </div>}
                <div>
                    <span
                        data-toggle="modal"
                        data-target="#learnMoreModal"
                        onClick={() => {
                            logAmplitudeEvent("Pressed Learn More Link", this.logProps);
                        }}
                    >
                        Learn More
                </span>
                </div>
            </>
        }

        const AccountInfoContent = () => {
            if (!this.props.bankName || !this.props.accountMask) {
                return null;
            }
            return <div className={styles.text}>
                <b>{this.props.bankName}</b> ending in <b>{this.props.accountMask}</b>
            </div>
        }
 
        const skipButton = () => {
            return <div className="row">
                <div className="col">
                    <div
                        data-test-id="skip"
                        className={btnSkipClasses}
                        onClick={this.handleOnSkipButtonClick}
                    >
                        SKIP
                </div>
                </div>
            </div>
        }
        const firstMonthPrepayment = this.props.firstMonthPrepayment;
        return (
            <Container>
                <PageHeader progress="60%">
                    <div>Automate Payments</div>
                    <div style={{ textAlign: "center" }}>
                        {firstMonthPrepayment ?
                            ["The debit card on file will only be used for your first payment. ",
                                "Never miss future loan payments by also linking your bank account.", <br/>] :
                            "Never miss a loan payment by automating payments from your&nbsp;linked&nbsp;account."}
                    </div>
                </PageHeader>
                <LoaderWithMessage />
                {!this.props.isLoading && <>
                <AccountInfoContent />
                    {!!this.props.errorMessage && (
                        <div className="row">
                            <div className="col">
                                <div className={styles.automatedPaymentsWarnSquare}>
                                    <div className={styles.automatedPaymentsWarnIcon}><img src={warnImage} alt="info-img"/></div>
                                    <div className={styles.automatedPaymentsWarnText}>
                                        {this.props.errorMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                {!this.props.errorMessage && (<img className={styles.image} src={image} alt="bank-logo-link" />)}
                <SubHeaderContent />
                <div className="row">
                    <div className="col">
                        <div
                            data-test-id="automate-payments"
                            className={btnFinishClasses}
                            onClick={this.handleOnButtonClick}
                        >
                            <span className="material-icons"
                                  style={{
                                      fontSize: "16px", marginRight: "5px", paddingBottom: "3px"
                                  }}
                            >
                                lock_outline
                            </span>
                            AUTOMATE PAYMENTS
                        </div>
                    </div>
                </div>
                {skipButton()}
                </>}
                <ConsumerLinkBankReadMoreModal />
                <PaymentAuthModal />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    extendedFraudAlert: state.consumer.extendedFraudAlert,
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    initExpired: state.consumer.initExpired,
    token: state.consumer.token,
    offerId: state.consumer.acceptedOfferId || state.consumer.offerId,
    errorMessage:
        state.consumer.errorMessage ||
        (state.consumer.loanAppId
            ? ""
            : "We’re having trouble setting up automated payments. Please try again or set up later."),
    isLoading: state.consumer.isLoading,
    status: state.consumer.status,
    selectedPlan: state.consumer.selectedPlan,
    selectedLoanOfferId: state.consumer.selectedLoanOfferId,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    statusId: state.consumer.statusId,
    statusAt: state.consumer.statusAt,
    bankVerificationRequired: state.consumer.bankVerificationRequired,
    moreInfoRequired: state.consumer.moreInfoRequired,
    bankName: state.consumer.bankName,
    accountMask: state.consumer.accountMask,
    autoPaymentsDecision: state.consumer.autoPaymentsDecision,
    acceptPlaidTokenRequestId: state.consumer.acceptPlaidTokenRequestId,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment
});

export default connect(
    mapStateToProps,
    {getLoanStatus, acceptPlaidToken}
)(AutopayConfirmationPage);

