import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {setError} from "../store/actions/consumerActions";
import {loadToken} from "@wisetack/shared-ui/utils/localStorage";
import {Route} from "react-router-dom";
import {Redirect} from "react-router";

/**
 * Tries to refresh loan application state by redirecting to the entry page with application token persisted in a local storage.
 * Redirects to error page when token is not found in a local storage. It happens for example when user types the link
 * to the browser omitting normal flow.
 * Useful when user reloads a page so Redux state is being cleared.
 */
function RequireLoanAppState({
                                 component,
                                 location,
                                 setError,
                                 ...rest
                             }) {

    let pageToRender = <Route
        {...rest}
        location={location}
        component={component}
    />

    let showErrorMsg = false;

    const loanAppId = useSelector(state => state.consumer.loanAppId);

    if (!loanAppId) {
        let redirectPath
        const token = loadToken();
        if (token) {
            redirectPath = "/" + token
        } else {
            redirectPath = "/error"
            showErrorMsg = true
        }
        pageToRender = <Redirect
            to={{
                pathname: redirectPath,
            }}
        />
    }

    useEffect(() => {
        if (showErrorMsg) {
            setError('Please use your payment link to log in.')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return pageToRender
}

export default connect(null, {setError})(
    RequireLoanAppState
);
