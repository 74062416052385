import React from "react";
import classNames from "classnames";
import styles from "./IncomeInfoModal.module.scss";
import image from "@wisetack/assets/img/info-icon.svg";

const IncomeInfoModal = () => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="incomeInfoModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`incomeInfoModalLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className={classNames("modal-header", styles.modalHeader)}>
                        <img src={image} alt="logo"/>
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-incomeInfoModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <h1>Yearly pre-tax income</h1>
                        <p>
                            The yearly pre-tax income is subject to verification.
                        </p>
                        <p>
                            I'm baby leggings cornhole slow-carb, banh mi pug next level listicle trust fund mumblecore.
                        </p>
                        <p>
                            For further assistance, contact <br/><a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IncomeInfoModal;
