import React from "react";
import styles from "./VerifyingYourIdentityModal.module.scss";
import classNames from "classnames";
import image from "@wisetack/assets/img/info-icon.svg";

const VerifyingYourIdentityModal = ({loanExpirationTime}) => {
  return (
    <div
      className={classNames("modal fade", styles.modal)}
      id="verifyingYourIdentityModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`tosLabel`}
      aria-hidden="false"
    >
      <div
        className={classNames("modal-dialog", styles.modalDialog)}
        role="document"
      >
        <div className={classNames("modal-content", styles.modalContent)}>
          <div className={classNames("modal-header", styles.modalHeader)}>
            <img src={image} alt="contract"/>
            <button
              type="button"
              className="close"
              data-test-id="close-verifyingYourIdentityModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={classNames("modal-body", styles.modalBody)}>
            <h1>Verifying your identity</h1>
            <p>
              In order to complete your application you may be required to verify your identity.
            </p>
            <p>
              We offer a secure way for you to scan your driver’s license.
            </p>
            <p>
              If you don’t complete this step by {loanExpirationTime}, your application will expire.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyingYourIdentityModal;
