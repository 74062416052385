import React, {useEffect} from 'react';
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";

import {prequalPatch} from "../store/actions/consumerActions";
import usePrequalFlow from "../hooks/usePrequalFlow";
import {PrequalPhoneForm} from "../components/PrequalPhoneForm";
import styles from "./PrequalPhonePage.module.scss";

const pageName = 'Prequal Verify Phone Page'

function PrequalPhonePage({prequalPatch}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const {applicationId, logAmplitude} = usePrequalFlow();

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    const handleSubmit = (mobileNumber) => {
        const data = {
            escalateAuthentication: true,
            mobileNumber
        }
        prequalPatch(
            applicationId,
            data,
            newRequest()
        );
        logAmplitude(pageName, 'Phone number submitted')
    }

    return <Container>
        <PageHeader  progress="0.3%">
            <div>
                Let’s try verifying your phone number again
            </div>
            <div className={styles.subtitle}>
                Enter a phone number where you can receive text&nbsp;messages.
            </div>
        </PageHeader>
        <ul className={styles.options}>
            <li>Must be the applicant's number</li>
            <li>A mobile phone you can access now</li>
        </ul>
        <PrequalPhoneForm requestInProgress={requestInProgress} onSubmit={handleSubmit}/>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
    </Container>
}

export default connect(null, {prequalPatch})(
    PrequalPhonePage
);
