import React from 'react'

import Log from '@wisetack/shared-ui/utils/Log';
import Header from './Header'

const About = (props) => {
    Log.info(props, `About props`);
    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header card-header-info">About</div>
                            <div className="card-body">
                                <h4 className="card-title">About product</h4>
                                <p className="card-text">Page for product info</p>
                                <button className="btn btn-primary">Read more &rarr;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;
