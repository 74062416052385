import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import classNames from "classnames";

import Log from '@wisetack/shared-ui/utils/Log';

class Header extends React.Component {

    render() {
        Log.info(this.props, `Navbar props`);
        if (this.props.hidden) return null;
        const NavLinkItem = (props) => {
            const classes = classNames(
                "nav-link",
                this.props.location.pathname === props.pathname && "active"
            );
            return (
                <li className="nav-item" style={{ marginTop: "5px" }}>
                    <Link className={classes} to={props.pathname}>{props.name}</Link>
                </li>
            )
        }

        return (
            <div className="container" style={{ marginBottom: "10px" }}>
                <ul className="nav nav-pills nav-pills-warning">
                    <NavLinkItem name="Home" pathname="/home" />
                    <NavLinkItem name="Plaid" pathname="/plaid" />
                    <NavLinkItem name="Customer Entry Page" pathname="/" />
                    <NavLinkItem name="About" pathname="/about" />
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(Header))
