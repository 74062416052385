import React from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";
import moment from "moment";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./LoanPayoutConfirmPage.module.scss";
import PurchaseConfirmationLoanOffer from "@wisetack/shared-ui/components/PurchaseConfirmationLoanOffer";
import usePayoutFlow from "../hooks/usePayoutFlow";
import classNames from "classnames";
import {confirmPurchase} from "../store/actions/consumerActions";
import useApiRequest from "@wisetack/shared-ui/utils/useApiRequest";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import usePayoutLog from "../hooks/usePayoutLog";
import ConfirmPayoutsModal from "./ConfirmPayoutsModal";

const pageName = "Payout Confirmation Page";

function LoanPayoutConfirmPage({confirmPurchase}) {
    const history = useHistory();

    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);
    const merchantName = useSelector(state => state.consumer.doingBusinessAs || state.consumer.merchantName);
    const loanAppId = useSelector(state => state.consumer.loanAppId);

    const [confirmRequestId, confirmRequestInProgress, confirmRequestError, newConfirmRequest] = useApiRequest('consumer');

    const payoutData = usePayoutFlow(selectedPlan, selectedPayout);

    const logAmplitude = usePayoutLog(pageName)

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/loan_payouts")
    }

    const handleYesClick = () => {
        logAmplitude("Release Payment Button clicked", {payoutId: payoutData.payoutId})
        confirmPurchase(loanAppId, payoutData.payoutId, "YES", newConfirmRequest())
    }

    const handleNoClick = () => {
        logAmplitude("Confirm Later Button clicked", {payoutId: payoutData.payoutId})
        confirmPurchase(loanAppId, payoutData.payoutId, "NO", newConfirmRequest())
    }

    return <Container>
        <PageHeader progress="90%" onBack={handleOnBack}>
            <div>Confirm {payoutData.approvedAmount} payout</div>
            <div/>
        </PageHeader>
        <div className={styles.content}>
            <p>
                Confirm to release <b>{payoutData.approvedAmount}</b> to <b>{merchantName}</b>. Or, come back once you’re ready.<br/>
                <span
                    data-toggle="modal"
                    data-target="#learnMoreConfirmPayoutsModal"
                    onClick={() => {
                        logAmplitude("Clicked Purchase Confirmation Learn More")
                    }}
                >
                  Learn More
                </span>
            </p>
            <p>
              <span
                  data-toggle="modal"
                  data-target="#purchaseConfirmationLoanOffer"
                  onClick={() => {
                      logAmplitude("Clicked Purchase Confirmation Loan Offer")
                  }}
              >
                Your offer
              </span>{" "}
              is valid until <strong>{moment(payoutData.expDate, "MM DD YYYY").format("LL")}</strong>.
            </p>
            <Error pageName={pageName}>{confirmRequestError}</Error>
            {!confirmRequestInProgress && <>
                <button
                    data-test-id="purchase-confirm-yes"
                    className={classNames("btn", styles.buttonEnabled)}
                    onClick={handleYesClick}
                >
                    RELEASE PAYOUT
                </button>
                <button
                    data-test-id="purchase-confirm-no"
                    className={classNames("btn", styles.buttonEnabledInverse)}
                    onClick={handleNoClick}
                >
                    CONFIRM LATER
                </button>
            </>}
            <LoaderWithMessage loading={!!confirmRequestId && !!confirmRequestInProgress}/>
        </div>
        <ConfirmPayoutsModal expirationDate={payoutData.expDate}/>
        <PurchaseConfirmationLoanOffer selectedPlan={payoutData} loanAppExpirationDate={payoutData.expDate}/>
    </Container>
}

export default connect(null, {confirmPurchase})(
    LoanPayoutConfirmPage
);
