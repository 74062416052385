import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {useEffect} from "react";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";

export default function usePayoutLog(page) {
    const location = useLocation();

    const loanAppId = useSelector(state => state.consumer.loanAppId);
    const status = useSelector(state => state.consumer.status);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);

    const logAmplitude = (eventType, eventProperties) => {
        let evProps = {
            page,
            path: location.pathname
        }
        if (loanAppId) {
            evProps.loanId = loanAppId.substr(0, 8)
        }
        if (status) {
            evProps.loanStatus = status
        }
        if (selectedPayout) {
            evProps.payoutId = selectedPayout.id
            evProps.payoutStatus = selectedPayout.status
        }
        if (eventProperties) {
            evProps = {
                ...evProps,
                ...eventProperties
            }
        }
        if (!eventType) {
            eventType = page
        }
        logAmplitudeEvent(eventType, evProps);
    }

    useEffect(() => {
        logAmplitude();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return logAmplitude;
}