import React from "react";
import classNames from "classnames";
import styles from "./PrequalUpdateInfoModal.module.scss";
import image from "@wisetack/assets/img/update.svg";

const PrequalUpdateInfoModal = () => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="prequalUpdateInfoModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`prequalUpdateInfoModalLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className={classNames("modal-header", styles.modalHeader)}>
                        <img src={image} alt="logo"/>
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-prequalUpdateInfoModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <h1>Update info</h1>
                        <p>
                            Do you need to update your information? Please contact us at <br/><a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrequalUpdateInfoModal;
