import React from "react";
import styles from "./LoaderWithMessage.module.scss";
import AnimatedLoader from "@wisetack/shared-ui/components/AnimatedLoader"
import Loader from "@wisetack/shared-ui/components/PulseLoader";

export function LoaderWithMessage({isLoading, duration}) {
    if (!isLoading) return null;
    if(!duration) duration = "short"

    if (duration === "long")
        return (
            <AnimatedLoader />
        )
    else  return (
        <div>
            <Loader />
            <div className={styles.message}>Wait a moment please...</div>
        </div>
    );
}
