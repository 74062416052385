import React from "react";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import classNames from "classnames";
import styles from "../views/ConsumerEntryPage.module.scss";

export function PrequalInfoPageHeader({progress}) {
    return <PageHeader progress={progress !== null ? progress : "0%"}>
        <div>We need a little more&nbsp;info</div>
        <div style={{textAlign: "center"}}>Tell us a bit more about yourself so we can see if you&nbsp;prequalify.
            <span
                data-toggle="modal"
                data-target="#consumerEntryPageMoreInfo"
                onClick={() => {
                    logAmplitudeEvent("Clicked More Info of Prequal Entry Page Link");
                }}
                className={classNames("material-icons", styles.infoIcon)}>info_outline</span>
        </div>
    </PageHeader>
}
