import React from "react";
import {useSelector} from "react-redux";
import parse from 'html-react-parser';

import {replaceLinkOptions} from "../utils/format";
import styles from "./PartnerOfferFooter.module.scss";

export const PartnerOfferFooter = () => {
    const settingsData = useSelector(state => state.consumer.settingsData);
    const footerText = settingsData && settingsData.footerText

    if (!footerText) {
        return null
    }
    return <div className={styles.footer}>{parse(footerText, replaceLinkOptions)}</div>
}