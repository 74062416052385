"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isString = exports.isObject = exports.getFormattedAmount = exports.formatZip = exports.formatUsPhoneToDomestic = exports.formatUsPhone = exports.formatSSN4 = exports.formatSSN = exports.formatOwnership = exports.formatNumber = exports.formatMobileNumberForApi = exports.formatEIN = exports.formatDateForInput = exports.formatCurrency = exports.formatAmount = exports.formatAPR = exports.decodeToken = exports.dateToYMD = void 0;
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}
var formatCurrency = function formatCurrency(val) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (!val) return val;
  val = val.toString().replace(/[^0-9.]/g, "");
  var firstDot = val.indexOf(".");
  var output = val.split(".");
  val = output.shift();
  val = Number(val.replace(/[^0-9]/g, "")).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  }).slice(0, -3);
  if (firstDot >= 0 && decimals > 0) {
    var decPart = output.join("").replace(/[^0-9]/g, "");
    if (decPart.length > decimals) decPart = decPart.substr(0, decimals);
    if (decPart !== "0".repeat(decimals)) {
      if (decPart.length === 1) decPart = decPart + "0";
      val = val + "." + decPart;
    }
  }
  if (val === "$0") val = "";
  if (val.match(/\./) === null && decimals > 0) val = "".concat(val, ".00");
  return val;
};
exports.formatCurrency = formatCurrency;
var formatAmount = function formatAmount(amount) {
  if (amount === undefined || isNaN(amount)) return "";
  if (amount === 0) return "$0";
  return formatCurrency(amount);
};
exports.formatAmount = formatAmount;
var formatMobileNumberForApi = function formatMobileNumberForApi(mobileNumber) {
  mobileNumber = mobileNumber.replace(/\D/g, "");
  if (mobileNumber.length === 10) {
    mobileNumber = "+1" + mobileNumber;
  } else if (mobileNumber.length === 11) {
    mobileNumber = "+" + mobileNumber;
  }
  return mobileNumber;
};
exports.formatMobileNumberForApi = formatMobileNumberForApi;
var formatUsPhone = function formatUsPhone(value) {
  if (!value) {
    return value;
  }
  var phone = value.replace(/\D/g, "");
  if (phone.length === 0) return "";
  if (phone[0] !== "1" && phone.length > 10) {
    phone = phone.substr(0, 10);
  }
  if (phone.length > 11) {
    phone = phone.substr(0, 11);
  }
  if (phone[0] === "1" && phone.length === 11) {
    var match = phone.match(/^(1)(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      phone = "".concat(match[1]).concat(match[2] ? "-" : "").concat(match[2]).concat(match[3] ? "-" : "").concat(match[3]).concat(match[4] ? "-" : "").concat(match[4]);
    }
  } else {
    var _match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (_match) {
      phone = "".concat(_match[1]).concat(_match[2] ? "-" : "").concat(_match[2]).concat(_match[3] ? "-" : "").concat(_match[3]);
    }
  }
  return phone;
};
exports.formatUsPhone = formatUsPhone;
var formatUsPhoneToDomestic = function formatUsPhoneToDomestic(value, mask) {
  if (!value) {
    return value;
  }
  var phone = value.replace(/\D/g, "");
  if (phone.length === 0) return "";
  if (phone[0] !== "1" && phone.length > 10) {
    phone = phone.substr(0, 10);
  }
  if (phone.length > 11) {
    phone = phone.substr(0, 11);
  }
  if (phone[0] === "1" && phone.length === 11) {
    var match = phone.match(/^(1)(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      phone = "".concat(match[2] ? "(" : "").concat(match[2]).concat(match[2] ? ")" : "", " ").concat(match[3]).concat(match[4] ? "-" : "").concat(mask ? "XXXX" : match[4]);
    }
  } else if (phone.length === 10) {
    var _match2 = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (_match2) {
      phone = "(".concat(_match2[1], ")", " ").concat(_match2[2]).concat(_match2[3] ? "-" : "").concat(mask ? "XXXX" : _match2[3]);
    }
  }
  return phone;
};
exports.formatUsPhoneToDomestic = formatUsPhoneToDomestic;
var formatEIN = function formatEIN(value) {
  if (value) {
    var ein = value.replace(/\D/g, "");
    if (ein.length === 0) return "";
    if (ein.length > 9) {
      ein = ein.substr(0, 9);
    }
    var match = ein.match(/^(\d{1,2})(\d{0,7})$/);
    if (match) {
      ein = "".concat(match[1]).concat(match[2] ? "-" : "").concat(match[2]);
    }
    return ein;
  }
  return value;
};
exports.formatEIN = formatEIN;
var formatSSN = function formatSSN(value) {
  var ssn = value.replace(/\D/g, "");
  if (ssn.length === 0) return "";
  if (ssn.length > 9) {
    ssn = ssn.substr(0, 9);
  }
  var match = ssn.match(/^(\d{1,3})(\d{0,2})(\d{0,4})$/);
  if (match) {
    ssn = "".concat(match[1]).concat(match[2] ? "-" : "").concat(match[2]).concat(match[3] ? "-" : "").concat(match[3]);
  }
  return ssn;
};
exports.formatSSN = formatSSN;
var formatNumber = function formatNumber(value, maxLength) {
  var n = value.replace(/\D/g, "");
  if (n.length === 0) return "";
  if (n.length > maxLength) {
    n = n.substr(0, maxLength);
  }
  return n;
};
exports.formatNumber = formatNumber;
var formatZip = function formatZip(value) {
  return formatNumber(value, 5);
};
exports.formatZip = formatZip;
var formatSSN4 = function formatSSN4(value) {
  return formatNumber(value, 4);
};
exports.formatSSN4 = formatSSN4;
var formatAPR = function formatAPR(percentage) {
  if (percentage === undefined || isNaN(percentage)) return "";
  var val = percentage.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  if (val.endsWith(".00")) val = val.slice(0, -3);
  return val + "% APR";
};
exports.formatAPR = formatAPR;
var formatOwnership = function formatOwnership(percentage) {
  var val = formatNumber(percentage, 3);
  if (!val) {
    return "";
  }
  if (parseInt(val, 10) > 100) {
    return "";
  }
  return val + "%";
};
exports.formatOwnership = formatOwnership;
var formatDateForInput = function formatDateForInput(miliseconds) {
  var date = new Date(miliseconds);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  return date.getFullYear() + "-" + month + "-" + day;
};
exports.formatDateForInput = formatDateForInput;
var getFormattedAmount = function getFormattedAmount(val, oldVal) {
  var newVal = "";
  var decimalArray = val.split(".");
  if (val[0] === "$" && val[1] === "0") {
    newVal = "$" + val.substr(2);
  } else if (decimalArray.length > 2 || decimalArray.length > 1 && decimalArray[decimalArray.length - 1].length > 2) {
    newVal = oldVal;
  } else {
    newVal = val;
  }
  if (newVal[0] !== "$") newVal = "$" + newVal;
  return newVal;
};
exports.getFormattedAmount = getFormattedAmount;
var dateToYMD = function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
};
exports.dateToYMD = dateToYMD;
var decodeToken = function decodeToken(token) {
  if (!token) {
    return null;
  }
  var jwt = (0, _jwtDecode["default"])(token);
  if (jwt.created) {
    jwt.created = new Date(jwt.created * 1000);
  }
  if (jwt.exp) {
    jwt.exp = new Date(jwt.exp * 1000);
  }
  return jwt;
};
exports.decodeToken = decodeToken;
var isString = function isString(val) {
  return typeof val === 'string' || !!val && _typeof(val) === 'object' && Object.prototype.toString.call(val) === '[object String]';
};
exports.isString = isString;
var isObject = function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || _typeof(val) === 'object';
};
exports.isObject = isObject;