"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postIframeEvent = void 0;
var postIframeEvent = function postIframeEvent(message) {
  if (window && window.parent) {
    window.parent.postMessage(message, "*");
  }
};
exports.postIframeEvent = postIframeEvent;