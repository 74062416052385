import React from "react";

import styles from "./RippleSpinner.module.scss";

export function RippleSpinner({show}) {
    if (!show) {
        return null;
    }
    return <div className={styles.spinner}>
    </div>
}