import {useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import {formatAmount} from "@wisetack/shared-ui/utils/format";

const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export default function usePayoutFlow(plan, payout) {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!plan || !payout) {
            history.push("/loan_payouts")
            return;
        }
        if (payout.path && location.pathname !== payout.path) {
            history.push(payout.path)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, payout]);

    if (!plan || !payout) {
        return {}
    }

    let totalPayments
    let interest

    const amount = payout.monthlyPayment

    if (payout.ratePercent !== 0) {
        totalPayments = round(amount * payout.termMonths, 2)
        interest = round(totalPayments - payout.approvedLoanAmount, 2)
    } else {
        totalPayments = round(payout.approvedLoanAmount, 2)
        interest = 0
    }

    return {
        payoutId: payout.id,
        payoutName: payout.payoutName,
        approvedAmount: formatAmount(payout.approvedLoanAmount),
        numberOfPayments: payout.termMonths,
        starting: payout.starting,
        apr: payout.ratePercent,
        expDate: payout.expDate,
        serviceCompletionProvidedByBorrower: payout.serviceCompletionProvidedByBorrower,
        amount,
        interest,
        totalPayments
    }
}