import React from "react";
import styles from "./PurchaseConfirmationLearnMore.module.scss";
import classNames from "classnames";
import image from "@wisetack/assets/img/info-icon.svg";
import moment from "moment";
import {getVerticalFragment} from "../utils/verticalMessages";

const PurchaseConfirmationLearnMore = ({loanAppExpirationDate, vertical, settlementDelay}) => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="purchaseConfirmationLearnMore"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`purchaseConfirmationLearnMoreLabel`}
            aria-hidden="false"
        >
            <div className={classNames("modal-dialog", styles.modalDialog)} role="document">
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-purchaseConfirmationLearnMore"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span className={styles.close} aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <img className={styles.logo} src={image} alt="bank-logo"/>
                        <h4>
                            {getVerticalFragment('confirmation_learn_more_header', vertical)}
                        </h4>
                        <div>
                            {getVerticalFragment('confirmation_learn_more_message', vertical, {
                                expirationDate: loanAppExpirationDate ? moment(loanAppExpirationDate, "MM DD YYYY").format("LL") : '',
                                settlementDelay: settlementDelay
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseConfirmationLearnMore;
