import React from "react";
import classNames from "classnames";
import styles from "./PromptModal.module.scss";
import image from "@wisetack/assets/img/info-icon.svg";

const PromptModal = () => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="whatsNextModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`whatsNextModalLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className={classNames("modal-header", styles.modalHeader)}>
                        <img src={image} alt="logo"/>
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-whatsNextModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <h1>What’s next?</h1>
                        <p>
                            To pay with Wisetack, confirm your purchase from the link in your invoice or one sent by the merchant.
                        </p>
                        <p>
                            Note that your prequalification lasts for 14 days. Approval and terms are not guaranteed; see actual terms in your financing application.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromptModal;
