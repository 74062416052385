"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.protocol = exports.isSecure = exports["default"] = void 0;
var PROD_DOMAIN = 'wisetack';
var secureDomains = [PROD_DOMAIN, 'wisetack-sec'];
var isSecure = function isSecure(domain) {
  if (!domain) {
    var _window$_wtenv_;
    domain = ((_window$_wtenv_ = window._wtenv_) === null || _window$_wtenv_ === void 0 ? void 0 : _window$_wtenv_.REACT_APP_DOMAIN_NAME) || PROD_DOMAIN;
  }
  return secureDomains.includes(domain);
};
exports.isSecure = isSecure;
var protocol = isSecure() ? "https" : "http";
exports.protocol = protocol;
var _default = protocol;
exports["default"] = _default;