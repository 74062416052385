import React from "react";
import {useSelector} from "react-redux";
import parse from 'html-react-parser';

import {replaceLinkOptions} from "../utils/format";
import styles from "./PartnerPrequalConsent.module.scss";

export const PartnerPrequalConsent = () => {
    const settingsData = useSelector(state => state.consumer.settingsData);
    const prequalConsentText = settingsData && settingsData.prequalConsentText

    if (!prequalConsentText) {
        return null
    }
    return <div className={styles.consent}>{parse(prequalConsentText, replaceLinkOptions)}</div>
}