import React from "react";
import {connect} from "react-redux";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./OfferLockPage.module.scss";
import Error from "@wisetack/shared-ui/components/Error";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {lockOffer} from "../store/actions/consumerActions";
import classNames from "classnames";
import LearnMoreLockModal from "@wisetack/shared-ui/components/LearnMoreLockModal";

const pageName = "Offer Lock Page";

class OfferLockPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName,
        };
        logAmplitudeEvent(pageName, this.logProps);
    }

    componentDidUpdate(prevProps) {
        if (this.props.lockRequestId && this.props.lockRequestId !== prevProps.lockRequestId) {
            if (this.props.status === "CONDITIONAL_APPROVAL" && this.props.firstMonthPrepayment) {
                this.props.history.replace("/card_collection");
            } else {
                this.props.history.replace("/link_bank");
            }
        }
    }

    continueHandler = () => {
        this.props.lockOffer(this.props.loanAppId, this.props.selectedLoanOfferId)
    }

    render() {

        const ContinueButton = () => {
            if (this.props.isLoading) {
                return null;
            }
            const btnDisabled = !this.props.loanAppId || !this.props.selectedLoanOfferId;
            const btnClasses = classNames({
                btn: true,
                "btn-block": true,
                "btn-disabled": btnDisabled,
                [styles.buttonDisabled]: btnDisabled,
                [styles.buttonEnabled]: !btnDisabled
            });

            return (
                <div className="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="col">
                        <button data-test-id="continue" className={btnClasses} onClick={this.continueHandler}>
                            CONTINUE WITH OFFER
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <Container>
                <PageHeader progress="42%">
                    <div>Let's lock in your offer</div>
                    <div></div>
                </PageHeader>
                <LoaderWithMessage />
                <div className={styles.message}>
                    {this.props.merchantName || 'Merchant'} just needs to confirm on their end. We'll give them a&nbsp;heads&nbsp;up.
                    <span
                        data-toggle="modal"
                        data-target="#learnMoreLockModal"
                        onClick={() => {
                            logAmplitudeEvent("Opened Learn more link", this.logProps);
                        }}
                    >
                        <b> Learn&nbsp;more</b>
                    </span>
                </div>
                <div className={styles.message}>
                    Complete your application to lock in&nbsp;your&nbsp;offer.
                </div>
                <Error pageName={pageName}>{this.props.errorMessage}</Error>
                <ContinueButton/>
                <LoaderWithMessage loading={this.props.isLoading} />
                <LearnMoreLockModal merchantName={this.props.merchantName} loanExpDate={this.props.loanAppExpirationDate} />
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    selectedLoanOfferId: state.consumer.selectedLoanOfferId,
    merchantName: state.consumer.merchantName,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage,
    status: state.consumer.status,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate,
    lockRequestId: state.consumer.lockRequestId,
    firstMonthPrepayment: state.consumer.firstMonthPrepayment,
});

export default connect(mapStateToProps, { lockOffer })(
    OfferLockPage
);
