import React, {useEffect} from "react";
import {connect} from "react-redux";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image from "@wisetack/assets/img/expired.svg";
import styles from "./PrequalExpiredPage.module.scss";

function PrequalExpiredPage() {

  useEffect(() => {
    logAmplitudeEvent('Prequal Expired Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container>
    <PageHeader progress="100%">
      <div className={styles.title}>Your prequalification has expired</div>
      <div/>
    </PageHeader>
    <img className={styles.logo} src={image} alt="expired"/>
    <div className={styles.message}>
      Please contact the merchant directly to start a new application.
    </div>
  </Container>
}

export default connect(null, {})(
    PrequalExpiredPage
);

