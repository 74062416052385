import React from "react";

import styles from "./CheckBox.module.scss";
import classNames from "classnames";
import {RippleSpinner} from "./RippleSpinner";

export function CheckBox({checked, loading, onClick}) {
    if (loading) {
        return <RippleSpinner show={true}/>
    }
    return <div data-test-id="checkbox" role="dialog" className={styles.box} onClick={onClick}>
        {checked &&
            <span className={classNames("material-icons", styles.done)}>done</span>
        }
    </div>
}