import React, {useEffect} from "react";
import {connect} from "react-redux";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image from "@wisetack/assets/img/expired.svg";
import styles from "./PendingPage.module.scss";

function PendingPage() {
    useEffect(() => {
        logAmplitudeEvent("Application Pending Page", {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Container>
        <PageHeader progress="100%">
            <div>Your application is pending</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="info-icon"/>
        <div className={styles.message}>
            Before we can continue, we will need to confirm some information provided.
        </div>
        <div className={styles.message}>
            You will receive a call at the number provided shortly.
        </div>
    </Container>
}

export default connect(null, {})(
    PendingPage
);
