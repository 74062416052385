import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classNames from "classnames";
import uuid from "uuid";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import PinForm from "@wisetack/shared-ui/components/PinForm";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import styles from "./ConsumerPinPage.module.scss";
import {setError, submitData} from "../store/actions/consumerActions";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

const pageName = "Pin Page"

class ConsumerPinPage extends React.Component {

  state = {
    pin: "",
    errors: {},
    suggestions: {}
  };

  constructor(props) {
    super(props);
    this.validator = new BorrowerFieldValidator(pageName);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logProps = {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    }
    logAmplitudeEvent(pageName, this.logProps);
    this.validator.props = this.logProps;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fieldsErrors && nextProps.fieldsErrors.pin) {
        Log.info(nextProps.fieldsErrors.pin, `fieldsErrors.pin`);
        return {
            suggestions: { pin: "Enter a valid verification code" }
        }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      if (this.props.errorMessage.includes('lockout')) {
        this.props.setError(null);
        this.props.history.push("/account_locked");
        return;
      }
      this.props.history.push("/error");
      return;
    }
    if (this.props.submitDataRequestId && this.props.submitDataRequestId !== prevProps.submitDataRequestId) {
      if (this.props.fieldsRequired.pin) {
        // while pin required stay on this page
        return;
      }
      // else route to the starting page (for further routing from this page)
      this.props.history.push("/" + this.props.token);
    }
  }

  validateField = (name, val) => {
    switch (name) {
      case "pin":
        return this.validator.validatePin(val);
      default:
        return "";
    }
  };

  handleOnBlur = e => { };

  setError(name, error) {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: error
      }
    });
  }

  handleOnButtonClick = () => {
    logAmplitudeEvent("Pressed Didn't get a code Button", this.logProps)
    if (this.props.location.pathname === '/phone_pin' || this.props.phoneVerified === false) {
      this.props.history.push("/phone_retry");
    } else {
      this.props.submitData(this.props.loanAppId, { newPinRequested: true })
    }
    this.setState({ pin: "" });
  };

  handleOnChange = e => {
    let val = e.target.value;
    let name = e.target.name;
    if (name === "pin") {
      val = val.replace(/\D/g, "");
      if (val.length > 4) {
        return;
      }
    }
    this.setState({ [name]: val });
    if (name === "pin" && val.length === 4) {
      logAmplitudeEvent("Entered pin", this.logProps)
      // send pin to server
      const requestId = uuid.v4();
      const payload = { authPin: val };
      this.props.submitData(this.props.loanAppId, payload, requestId);
    }
  };

  render() {
    Log.info(this.props, `ConsumerPinPage props`);

    const btnClasses = classNames({
      [styles.button]: true
    });


    const showContent = !this.props.isLoading;
    let mobileNumber = this.props.mobileNumber;
    if (mobileNumber) {
      mobileNumber = formatUsPhone(mobileNumber);
      if (mobileNumber.startsWith('1-')) {
        mobileNumber = mobileNumber.substr(2);
      }
    }

    const Title = () => {
      return <div>We just texted you</div>
    }

    return (
      <Container>
        <PageHeader progress="0.3%">
          <Title/>
          <div style={{ paddingLeft: "15px" }}>
            Please enter the verification code
            <br />
            we&nbsp;sent&nbsp;to&nbsp;<b>{mobileNumber}</b>
          </div>
        </PageHeader>
        <LoaderWithMessage isLoading={this.props.isLoading} />
        <div style={{ paddingBottom: "25px" }}>
          {showContent && (
            <>
              <PinForm>
                <FormRow>
                  <FormInput
                    autoFocus
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    name="pin"
                    label="Enter the 4-digit code"
                    value={this.state.pin}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    suggestions={this.state.suggestions}
                  />
                </FormRow>
              </PinForm>
              <Error pageName={pageName}>{this.props.errorMessage}</Error>
              <div className="row">
                <div className="col">
                  <div
                    className={btnClasses}
                    onClick={this.handleOnButtonClick}
                  >
                    Didn't get a code?
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
}

ConsumerPinPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  token: state.consumer.token,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  mobileNumber: state.consumer.mobileNumber || state.consumer.fieldsValue.pin,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  fieldsRequired: state.consumer.fieldsRequired,
  fieldsValue: state.consumer.fieldsValue,
  fieldsErrors: state.consumer.fieldsError,
  status: state.consumer.status,
  initExpired: state.consumer.initExpired,
  phoneVerified: state.consumer.phoneVerified,
  submitDataRequestId: state.consumer.submitDataRequestId,
  profileFound: state.consumer.profileFound,
  firstName: state.consumer.firstName,
  expectedRunLength: state.consumer.expectedRunLength,
});

export default connect(
  mapStateToProps,
  { submitData, setError }
)(ConsumerPinPage);
