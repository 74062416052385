import React from 'react'

import Log from '@wisetack/shared-ui/utils/Log'
import Header from './Header'

import axios from 'axios';

class Home extends React.Component {

    state = {
        errorMessage: null,
        isLoading: false,
        response: null
    };

    handleOnClick = async () => {
        this.setState({
            isLoading: true,
            response: null,
            ErrorMessage: null
        });
        this.cancelTokenSource = axios.CancelToken.source()
        try {
            const response = await axios.post("https://httpbin.org/anything", {
                customerId: "customer_1",
                token: "token_1"
            }, {
                    cancelToken: this.cancelTokenSource.token
            });
            this.setState({
                isLoading: false,
                response: response.data
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                // ignore
            } else {
                let errorMessage = 'http request error';
                if (err.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    errorMessage = JSON.stringify(err.response, null, 2);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = err.message;
                }
                this.setState({
                    isLoading: false,
                    errorMessage
                });
            }
        } finally {
            this.cancelTokenSource = null;
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource && this.cancelTokenSource.cancel()
    }

    render() {
        Log.info(this.props, `Home props`);
        const ErrorMessage = () => {
            return this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div> : null
        }
        const Loading = () => {
            return this.state.isLoading ? <div className="progress">
                <div className="progress-bar progress-bar-striped" style={{ width: "100%" }} /></div> : null
        }
        const Response = () => {
            return this.state.response ? <div className="alert"><pre><code>{JSON.stringify(this.state.response, null, 2)}</code></pre></div> : null
        }
        return (
            <>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header card-header-info">Home</div>
                                <div className="card-body">
                                    <h4 className="card-title">Wisetack web application home page</h4>
                                    <p className="card-text">Page for CSP testing</p>
                                    <button className="btn btn-primary" onClick={this.handleOnClick}>Test request</button>
                                    <ErrorMessage />
                                    <Loading />
                                    <Response />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;
