import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import appCanceled from "@wisetack/assets/img/app-canceled.svg";
import styles from "./ConsumerConfirmationPage.module.scss";

class ConsumerCanceledPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Declined Page (due to Pre-activated Merchant)", {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            cancelReason: this.props.cancelReason
        });
    }

    render() {
        return (
            <Container>
                <PageHeader progress="80%">
                    <Fragment>
                        <strong>Application canceled</strong>
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    <div style={{ textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>
                        <img className={styles.image} src={appCanceled} alt="app-canceled"/>
                    </div>
                    <p style={{ textAlign: "center" }}>
                        Your financing application was successfully canceled.
                    </p>
                    <p style={{ textAlign: "center" }}>
                        Please reach out to the merchant if you need to re-apply. For additional questions or assistance, <a href="mailto:support@wisetack.com">contact support</a>.
                    </p>
                </div>
            </Container>
          );
    }
}

const mapStateToProps = state => ({
    cancelReason: state.consumer.cancelReasonsList ? state.consumer.cancelReasonsList[0] : null,
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    status: state.consumer.status,
    transactionAmount: state.consumer.transactionAmount,
    firstName: state.consumer.firstName,
    selectedPlan: state.consumer.selectedPlan,
    serviceCompletionDate: state.consumer.serviceCompletionDate
});
  
export default connect(mapStateToProps, {})(ConsumerCanceledPage);
