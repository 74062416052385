import React, { Fragment } from "react";
import { connect } from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import plane from "@wisetack/assets/img/paper-plane.svg";
import styles from "./ConsumerConfirmationPage.module.scss";
import moment from "moment";

class ConsumerConfirmationDeniedFuturePage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Consumer not received product page (Service date in future)", {});
    }

    render() {

        let date = '';
        if (this.props.serviceCompletionDate) {
            date = moment(this.props.serviceCompletionDate).format("LL")
        }

        return (
            <Container>
                <PageHeader progress="85%">
                    <Fragment>
                        No problem! We'll contact you later 
                        <br/>
                        for a final confirmation
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    <p>
                        It looks like you'll receive the product or service from {this.props.merchantName} on {date}
                    </p>
                    <p>
                        We'll contact you then, and you can also return from your application link. Your financing won't start until we get your confirmation.
                    </p>
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                        <img className={styles.image} src={plane} alt="paper-plane"/> 
                    </div>
                </div>
            </Container>
          );
    }
}

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    status: state.consumer.status,
    firstName: state.consumer.firstName,
    selectedPlan: state.consumer.selectedPlan,
    serviceCompletionDate: state.consumer.serviceCompletionDate
});
  
export default connect(mapStateToProps, {})(ConsumerConfirmationDeniedFuturePage);