import React from "react";
import moment from "moment";
import classNames from "classnames";

import styles from "./LoanPayout.module.scss";
import {formatAmount} from "@wisetack/shared-ui/utils/format";

export const LoanPayout = ({item, onClick}) => {
    const classes = {
        [styles.nav]: true
    }
    if (item.disabled) {
        classes[styles.grey] = true
    } else if (item.status === "OFFER_AVAILABLE" || item.status === "COUNTER_OFFER_AVAILABLE") {
        classes[styles.mint] = true
    } else {
        classes[styles.black] = true
    }

    const iconClasses = {
        "material-icons": true,
        [styles.icon]: true
    }
    if (item.status === "OFFER_CONVERTED" || item.status === "SETTLED") {
        iconClasses[styles.mint] = true
    } else {
        iconClasses[styles.grey] = true
    }

    return <div onClick={onClick} className={styles.details}>
        <div className={styles.name}>
            {item.payoutName}
            <span className={classNames(iconClasses)}>check_circle_outlined</span>
        </div>
        <div className={classNames(classes)}>
            <span className={styles.amount}>{formatAmount(item.approvedLoanAmount)}</span>
            <span className={styles.delim}>|</span>
            <span className={styles.status}>{item.statusName}</span>
            <span className={classNames("material-icons", styles.open_icon)}>arrow_forward_ios</span>
        </div>
        <div className={styles.exp}>Exp. Date: {moment(item.expDate).format("MMM. D, YYYY")}</div>
    </div>
}

