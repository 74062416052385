"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveTokenTTL = exports.saveToken = exports.saveSubmitted = exports.saveLoanAppId = exports.removeToken = exports.loadTokenTTL = exports.loadToken = exports.loadLoanAppId = exports.isSubmitted = void 0;
var saveToken = function saveToken(token) {
  try {
    localStorage.setItem('token', token);
  } catch (_unused) {
    // ignore write errors
  }
};
exports.saveToken = saveToken;
var removeToken = function removeToken() {
  try {
    localStorage.removeItem('token');
  } catch (_unused2) {
    // ignore write errors
  }
};
exports.removeToken = removeToken;
var saveTokenTTL = function saveTokenTTL(token) {
  try {
    localStorage.setItem("tokenTTL:".concat(token), Date.now() + 23 * 60 * 60 * 1000); // 23h
  } catch (_unused3) {
    // ignore write errors
  }
};
exports.saveTokenTTL = saveTokenTTL;
var saveLoanAppId = function saveLoanAppId(id) {
  try {
    localStorage.setItem('loanAppId', id);
  } catch (_unused4) {
    // ignore write errors
  }
};
exports.saveLoanAppId = saveLoanAppId;
var loadToken = function loadToken() {
  try {
    var token = localStorage.getItem('token');
    if (token === null) {
      return undefined;
    }
    return token;
  } catch (err) {
    return undefined;
  }
};
exports.loadToken = loadToken;
var loadTokenTTL = function loadTokenTTL(token) {
  try {
    var tokenTTL = localStorage.getItem("tokenTTL:".concat(token));
    if (tokenTTL === null) {
      return undefined;
    }
    return tokenTTL;
  } catch (err) {
    return undefined;
  }
};
exports.loadTokenTTL = loadTokenTTL;
var loadLoanAppId = function loadLoanAppId() {
  try {
    var id = localStorage.getItem('loanAppId');
    if (id === null) {
      return undefined;
    }
    return id;
  } catch (err) {
    return undefined;
  }
};
exports.loadLoanAppId = loadLoanAppId;
var saveSubmitted = function saveSubmitted(id) {
  try {
    localStorage.setItem("submit:".concat(id), true);
  } catch (_unused5) {
    // ignore write errors
  }
};
exports.saveSubmitted = saveSubmitted;
var isSubmitted = function isSubmitted(id) {
  try {
    var submit = localStorage.getItem("submit:".concat(id));
    if (submit === null) {
      return false;
    }
    return submit;
  } catch (err) {
    return false;
  }
};
exports.isSubmitted = isSubmitted;